import { useTranslation } from 'react-i18next'
import { Input } from 'antd'

const { TextArea: TextareaAnt } = Input

export default function Textarea(props) {
  const { t } = useTranslation()

  return <TextareaAnt placeholder={t('form.empty-field')} rows={3} {...props} />
}
