import { useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import { useSettings } from 'src/settings/SettingsApi'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Button, Input } from 'antd'
import { useTranslation } from 'react-i18next'

export default function PageSearch({ translateKey, search, setSearch, compact }) {
  const { t } = useTranslation()
  const { data: settings } = useSettings()

  const [isSearchOpen, setSearchOpen] = useState(false)
  const searchRef = useRef(null)

  useHotkeys('alt+f', () => [setSearchOpen(true), searchRef.current?.focus()], { enabled: settings.shortcuts_enabled })

  return (
    <>
      {isSearchOpen && (
        <Input
          size={compact ? 'small' : 'default'}
          ref={searchRef}
          onKeyUp={(event) => {
            if (event.key === 'Escape') {
              setSearch('')
              setSearchOpen(false)
            }
          }}
          style={{ width: 200 }}
          prefix={<FontAwesomeIcon className="mr-1" icon={regular('magnifying-glass')} />}
          autoFocus
          onBlur={() => !search.length && setSearchOpen(false)}
          allowClear
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          variant="filled"
          placeholder={t(`${translateKey}.page.search`)}
        />
      )}
      {!isSearchOpen && (
        <Button
          size={compact ? 'small' : 'default'}
          onClick={() => [setSearch(''), setSearchOpen(true)]}
          type="text"
          icon={<FontAwesomeIcon icon={regular('magnifying-glass')} />}
        ></Button>
      )}
    </>
  )
}
