import { useState } from 'react'
import { Navigate, useLocation, Link, useNavigate } from 'react-router-dom'

import { useAuth } from 'src/auth/Auth'
import { supabase } from 'src/supabase'

import logo from 'src/assets/universus.png'
import Label from 'src/@components/Label'
import { Button, Input, Typography, Form, message } from 'antd'
import { useTranslation } from 'react-i18next'


const { Title, Text, Link: LinkAnt } = Typography
const { APP_SIGNIN_CALLBACK } = import.meta.env

export default function ForgotPassword() {
  const { t } = useTranslation()
  const { session } = useAuth()
  const navigate = useNavigate()
  const { state: pathname } = useLocation()
  const [messageApi, contextHolder] = message.useMessage()
  const [isLoading, setLoading] = useState(false)

  if (session) {
    return <Navigate to={pathname || '/'} />
  }

  async function onFinish(values) {
    setLoading(true)

    const { data, error } = await supabase.auth.resetPasswordForEmail(values.email, {
      redirectTo: APP_SIGNIN_CALLBACK
    })

    setLoading(false)

    if (error) {
      messageApi.open({
        type: 'error',
        content: error.message,
        duration: 0,
      })
    } else {
      navigate('/sign-in', {
        state: {
          forgotPassword: true
        }
      })
    }
  }

  return (
    <div className="box-border min-h-screen w-full bg-base py-16">
      <div className='w-[400px] mx-auto flex flex-col'>
        <Link to="/sign-in">
          <div className="flex cursor-pointer items-center gap-4">
            <img src={logo} className='w-14 h-14' />
            <Text strong className="text-lg" style={{
              fontFamily: '"Raleway", sans-serif',
              fontOpticalSizing: "auto",
              fontWeight: 600,
              fontStyle: "normal"
            }}>
              UNIVERSUS
            </Text>
          </div>
        </Link>

        {contextHolder}
        <Title level={2} style={{ marginBottom: 0 }}>{t('auth.reset-password')}</Title>
        <Text type="secondary">{t('auth.reset-password-text')}</Text>

        <Form validateTrigger={'onBlur'} disabled={isLoading} onFinish={onFinish} className="flex flex-col gap-4 mt-6">
          <Label label={t('auth.email')}>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: t('rules.email-required') },
                {
                  pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: t('rules.email-valid'),
                },
              ]}
            >
              <Input placeholder="you@example.com" />
            </Form.Item>
          </Label>

          <Button loading={isLoading} htmlType="submit" block type="primary">
            {t('auth.reset-password-link')}
          </Button>
        </Form>

        <div className="mt-4 flex w-full justify-center">
          <Text type="secondary">
            {t('auth.already-have-account')}{' '}
            <Link to="/sign-in">
              <LinkAnt>{t('auth.sign-in')}</LinkAnt>
            </Link>
          </Text>
        </div>
      </div>
    </div>
  )
}
