import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Empty, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

const { Text } = Typography

export default function EmptyLoading({ icons, compact, translateKey, section }) {
  const { t } = useTranslation()

  return (
    <div className="flex w-full grow items-center justify-center p-6 box-border">
      <Empty
        image={null}
        imageStyle={{ display: 'none' }}
        description={
          <div className="flex flex-col gap-4 relative">
            <Text type="secondary">
              <FontAwesomeIcon icon={section.icon || icons.duotone} size={compact ? "3x" : "5x"} />
              <FontAwesomeIcon
                style={{ left: -30, bottom: 2 }}
                className="fa-spinner text-primary absolute opacity-60"
                icon={solid('spinner')}
                size="lg"
              />
            </Text>
            <Text type="secondary">
              <strong>{t(`${translateKey}.page.sections.${section.value}.loading`)}</strong>
            </Text>
          </div>
        }
      />
    </div>
  )
}
