import { forwardRef, useContext, useEffect, useState } from 'react'

import Select from 'src/@components/form/Select'
import { PageContext } from 'src/@components/page/Page'

const CellSelectEditor = forwardRef(
  ({ tags, query, queryParams, options, value: oldValue, data, colDef: { field }, stopEditing, api, eventKey }, ref_) => {
    const [value, setValue] = useState(oldValue)
    const [search, setSearch] = useState(/^[a-zA-Z]$/.test(eventKey) ? eventKey : '')
    const [isSearchInit, setSearchInit] = useState(false)
    const { update } = useContext(PageContext)

    const { data: _options } = query ? query({ queryParams }) : { data: options }

    const save = () => {
      if (oldValue !== value) {
        if (api.pageSection !== '*') {
          api.applyTransactionAsync({
            remove: [{ id: data.id }],
          })
        } else {
          api.applyTransactionAsync({
            update: [{ ...data, [field]: value }],
          })
        }
        update({ id: data.id, [field]: value })
      }

      stopEditing()
    }

    useEffect(() => save, [value])

    return (
      <div className="h-full w-full">
        <Select
          tags={tags}
          autoFocus
          style={{ width: '100%', height: '100%' }}
          open={true}
          variant="filled"
          value={value}
          // https://github.com/ant-design/ant-design/issues/20168
          onSearch={(value) => {
            if (isSearchInit) {
              setSearch(value)
            } else {
              setSearchInit(true)
            }
          }}
          searchValue={search}
          onBlur={stopEditing}
          onChange={setValue}
          options={_options.map(option => query ? { label: option.name, value: option.id } : option)}
        />
      </div>
    )
  }
)

export default CellSelectEditor
