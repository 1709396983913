import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'src/auth/Auth'

import { useUI } from 'src/layout/UI'

import { supabase } from 'src/supabase'

export const useSettings = () => {
  const { setTheme, setLang, lang } = useUI()
  const { session } = useAuth()

  const settings = localStorage.getItem('settings')

  return useQuery({
    queryKey: ['settings'],
    queryFn: async () => {
      const { data } = await supabase.from('profile_settings').select('*').eq("profile_id", session.user.user_metadata.profile_id).single()

      setTheme(data.theme)
      if (data.lang) {
        setLang(data.lang)
      }

      localStorage.setItem('settings', JSON.stringify(data))

      return data
    },
    initialData: settings ? JSON.parse(settings) : {},
    refetchOnMount: false,
  })
}

export const useUpdateSettings = () => {
  const queryClient = useQueryClient()
  const { setTheme } = useUI()
  const { id } = queryClient.getQueryData(['settings'])
  const { i18n } = useTranslation()

  return useMutation({
    mutationFn: async (values) => supabase.from('profile_settings').update(values).eq('id', id),
    onMutate: (values) => {
      if (values.theme) {
        setTheme(values.theme)
      }

      if (values.lang) {
        i18n.changeLanguage(values.lang)
      }

      queryClient.setQueryData(['settings'], (old) => ({ ...old, ...values }))
    },
  })
}