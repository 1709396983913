import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Typography, Spin } from 'antd'
import logo from 'src/assets/universus.png'
import { useTranslation } from 'react-i18next'
import { supabase } from '../supabase';

const { Text } = Typography

export default function MissingDocumentsCancel() {
  const { t } = useTranslation()
  
  const [status, setStatus] = useState('loading');
  const [searchParams] = useSearchParams();

  useEffect(() => {
    supabase.functions.invoke('decline-missing-documents-request', { body: { token: searchParams.get('t') } }).then(({ error }) => (
      setStatus(error ? 'fail' : 'success')
    ));
  }, [])

  return (
    <div>
      <div className="absolute top-2 left-2 flex items-center" style={{ left: '0.5rem', gap: '0 0.5rem' }}>
        <img src={logo} className='w-14 h-14'/>
        <Text strong className="text-lg" style={{
          fontFamily: '"Raleway", sans-serif',
          fontOpticalSizing: "auto",
          fontWeight: 600,
          fontStyle: "normal"
        }}>
          UNIVERSUS
        </Text>
      </div>
      <div className="box-border min-h-screen w-full bg-base py-12 flex justify-center items-center">
        <div className='w-[400px] flex justify-center items-center'>
          {status === 'loading' && <Spin size="large" />}
          {['success', 'fail'].includes(status) && (
            <Text type="primary" style={{ whiteSpace: 'pre-line' }}>
              {t(`missing-documents.${status}`)}
            </Text>
          )}
        </div>
      </div>
    </div>
  )
}
