import { useEffect, useRef, useState } from 'react'
import { Navigate, useLocation, Link, useParams } from 'react-router-dom'

import { useAuth } from 'src/auth/Auth'
import { supabase } from 'src/supabase'

import { Button, Input, Typography, Form, message } from 'antd'
import Universus from 'src/assets/universus.png'
import Label from 'src/@components/Label'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

const { Title, Text } = Typography

export default function SignUp() {
  const { t } = useTranslation()
  const { session } = useAuth()
  const location = useLocation()
  const [messageApi, contextHolder] = message.useMessage()
  const [isLoading, setLoading] = useState(false)
  const [logo, setLogo] = useState(localStorage.getItem('logo'))
  const [isLoadingCompany, setLoadingCompany] = useState(!logo)
  const refPassword = useRef(null)
  const { token } = useParams()


  if (session) {
    return <Navigate to={location.state?.pathname || '/'} />
  }

  useEffect(() => {
    const fetchToken = async () => {
      const { data, error } = await supabase.from('signup_tokens').select('logo').eq('token', token).single()
      setLoadingCompany(false)
      if (data) {
        setLogo(data.logo)
        localStorage.setItem('logo', data.logo)
      }
    }

    fetchToken()
  }, [])

  async function onFinish({ password }) {
    setLoading(true)

    const { data, error } = await supabase.functions.invoke('connect-user', {
      body: {
        token,
        password
      }
    })
    
    messageApi.destroy()
    
    if (data.error || error) {
      setLoading(false)
      messageApi.open({
        type: 'error',
        content: t('auth.signing-up-error'),
        duration: 0,
      })
    } else {
      window.location.href = data.magicLink;
    }
  }

  function isValidURL(string) {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  }

  return (
    <div className="box-border min-h-screen w-full bg-base py-16">
      <div className='w-[400px] mx-auto flex flex-col'>
        <Link to="/sign-in" className="flex items-center justify-between">
          <div className="flex col cursor-pointer items-center gap-4">
            <img src={Universus} className='w-14 h-14' />
            <Text strong className="text-lg" style={{
              fontFamily: '"Raleway", sans-serif',
              fontOpticalSizing: "auto",
              fontWeight: 600,
              fontStyle: "normal"
            }}>
              UNIVERSUS
            </Text>
          </div>
          <div>
            {isLoadingCompany && <Text><FontAwesomeIcon size="2xl" className={"fa-spinner"} icon={light('circle-notch')} /></Text>}
            {logo && <>
              {isValidURL(logo) && <img style={{ height: 42 }} src={logo} />}
              {!isValidURL(logo) && <Text>{logo}</Text>}
            </>}
          </div>
        </Link>

        {contextHolder}
        <Title level={2} style={{ marginBottom: 0 }}>{t('auth.welcome')}</Title>
        <Text type="secondary">{t('auth.sign-up-text')}</Text>

        <Form validateTrigger={'onBlur'} disabled={isLoading} onFinish={onFinish} className="flex flex-col gap-4 mt-6">
          <Label label={t("settings.my-account.password")}>
            <Form.Item
              rules={[
                { required: true, message: t("rules.password-required") },
                { min: 6, message: t("rules.password-length") },
              ]}
              name="password"
            >
              <Input.Password placeholder="••••••••" />
            </Form.Item>
          </Label>
          <Label label={t("settings.my-account.confirm-password")}>
            <Form.Item
              rules={[
                { required: true, message: t("rules.password-required") },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error(t("settings.my-account.password-match")))
                  },
                }),
              ]}
              name="repeat"
            >
              <Input.Password placeholder="••••••••" />
            </Form.Item>
          </Label>

          <Button loading={isLoading} htmlType="submit" block type="primary">
            {t('auth.sign-in')}
          </Button>
        </Form>
      </div>
    </div>
  )
}
