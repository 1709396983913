import { useContext, useEffect, useMemo, useRef } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import { useSettings } from 'src/settings/SettingsApi'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import 'src/@components/table/Table.css'
import { AgGridReact } from 'ag-grid-react'

import clsx from 'clsx'
import CellHeader from 'src/@components/table/CellHeader'
import TableFooter from 'src/@components/table/TableFooter'
import CellHeaderExtra from 'src/@components/table/CellHeaderExtra'
import { useTranslation } from 'react-i18next'
import { PageContext } from 'src/@components/page/Page'
import Typography from 'antd/es/typography/Typography'
import { useAuth } from 'src/auth/Auth'
import { useProfile } from 'src/profiles/ProfilesApi'

export default function Table({
  data = [],
  columns: _columns,
  openForm,
  // count,
  section,
  compact,
  isFormOpen,
}) {
  const { data: settings } = useSettings()
  const { session } = useAuth()
  const { data: profile } = useProfile()
  const { t } = useTranslation()
  const { translateKey } = useContext(PageContext)
  const gridRef = useRef(null)
  const count = data.length

  useEffect(() => {
    if (gridRef.current.api) {
      //@TODO: Using the old state that way, a solution is to use Context
      gridRef.current.api.pageSection = section.value
    }
  }, [gridRef.current?.api, section])

  const columns = useMemo(() => {
    return _columns
      .filter(column => !column.noTable)
      .map(column => {
        let headerName = ''
        if (column.field) {
          let translateProp = column.field
          
          if (column.specificName && column.specificName.includes(profile.company?.name)) {
            translateProp += '_' + profile.company?.name.toLowerCase()
          }

          headerName = t(`${translateKey}.fields.${translateProp}`) 
        } else {
          headerName = column.field_ 
        }

        return { 
          ...column, 
          headerName
        }
      })
  }, [section])

  useHotkeys(
    ['ArrowLeft', 'ArrowUp'],
    (e) => {
      e.preventDefault()
      if (!gridRef.current.api.getFocusedCell()) {
        gridRef.current.api.ensureIndexVisible(gridRef.current.props.data.length - 1)
        gridRef.current.api.setFocusedCell(
          gridRef.current.props.data.length - 1,
          gridRef.current.props.columnDefs[0].field
        )
      }
    },
    { enabled: settings.shortcuts_enabled }
  )

  useHotkeys(
    ['ArrowRight', 'ArrowDown'],
    (e) => {
      e.preventDefault()
      if (!gridRef.current.api.getFocusedCell()) {
        gridRef.current.api.ensureIndexVisible(0)
        gridRef.current.api.setFocusedCell(0, gridRef.current.props.columnDefs[0].field)
      }
    },
    { enabled: settings.shortcuts_enabled }
  )

  useHotkeys(
    ['esc'],
    () => {
      gridRef.current.api.clearFocusedCell()
      document.activeElement.blur()
      document.body.focus()
    },
    { enabled: settings.shortcuts_enabled }
  )

  return (
    <>
      <div className={clsx('ag-theme-table', data.length ? 'grow' : 'h-8', isFormOpen && 'drawerOpen', !data.length && 'empty')}>
        <AgGridReact
          ref={gridRef}
          getRowId={(params) => params.data.id}
          rowData={data}

          // onCellDoubleClicked={(e) => {
          //   if (!e.eventPath.some((element) => element.tagName === 'BUTTON')) {
          //     gridRef.current.api.startEditingCell({
          //       rowIndex: e.rowIndex,
          //       colKey: e.colDef.field,
          //       charPress: null,
          //     })
          //   }
          // }}

          onCellClicked={(props) => {
            if (!props.eventPath.some((element) => element.tagName === 'BUTTON')) {
              openForm(props.data)
            }
          }}

          columnDefs={[
            ...columns,
            {
              field: '',
              flex: 1,
              resizable: false,
              headerComponent: CellHeaderExtra,
              headerComponentParams: { compact },
              cellRenderer: () => <></>,
              lockPosition: 'right',
            },
          ]}
          rowSelection="single"
          suppressClickEdit={true}
          reactiveCustomComponents={true}
          defaultColDef={{
            width: compact ? 140 : 160,
            sortable: false,
            headerComponent: CellHeader,
            cellRenderer: ({ value }) => (
              value ? <div className="box-border w-full overflow-hidden text-ellipsis">{value}</div> : <Typography.Text type="secondary">{t('form.empty-field')}</Typography.Text>
            ),
            suppressKeyboardEvent: ({ editing, event: { key, altKey }, data }) => {
              if (editing && key === 'Enter') {
                return true
              }

              if (settings.shortcuts_enabled && !editing && altKey && key === 'c') {
                openForm(data)
                return true
              }
            },
          }}
          headerHeight={32}
          rowHeight={40}
          domLayout={compact && 'autoHeight'}
          suppressScrollOnNewData
          suppressNoRowsOverlay
        />
      </div>

      {/* {!!data.length && !compact && <TableFooter count={count} />} */}
    </>
  )
}
