import { useEffect, useMemo, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { Link, useNavigate } from 'react-router-dom'
import { omit } from 'lodash'

import { useSettings } from 'src/settings/SettingsApi'

import PageSearch from 'src/@components/page/PageSearch'
import PageFilter from 'src/@components/page/PageFilter'
import PageSort from 'src/@components/page/PageSort'
import PageMenu from 'src/@components/page/PageMenu'
import Label from 'src/@components/Label'
import { useTranslation } from 'react-i18next'

import { Button, Tabs, Typography, Badge, theme, Progress } from 'antd'
import clsx from 'clsx'
import { supabase } from 'src/supabase'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAuth } from 'src/auth/Auth'
import { useProfile, useTranscriber } from 'src/profiles/ProfilesApi'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

const { Text } = Typography
const { useToken } = theme

export default function PageHeader({
  compact,
  translateKey,
  data,
  isVisible,
  sections,
  counts,
  columns,
  section,
  hideAction,
  action,
  search,
  style,
  icons,
  centered,
  filters,
  setFilters,
  PageHeaderExtra,
  sorts,
  setSorts,
  setSearch,
  openForm,
}) {
  const { t } = useTranslation()
  const { data: settings } = useSettings()
  const { data: profile } = useProfile()
  const { data: transcriber } = useTranscriber()
  const { session } = useAuth()
  const { token } = useToken()
  const navigate = useNavigate()
  const persistedLastSeenCompleted = localStorage.getItem('lastSeenCompleted')
  const [countNewCompleted, setCountNewCompleted] = useState(0)

  const lastSeenCompleted = useRef(persistedLastSeenCompleted ? new Date(persistedLastSeenCompleted) : new Date())
  const currentSection = useRef(section.value)

  useEffect(() => {
    currentSection.current = section.value
  }, [section.value])

  const resetLastSeenCompleted = async () => {
    const x = new Date()
    lastSeenCompleted.current = x
    localStorage.setItem('lastSeenCompleted', x.toISOString())
  }

  useEffect(() => {
    const getLastCompleted = async () => {
      let query

      if (profile.universus && session.user.user_metadata.transcriber_id && !settings.permissions['read-folders']) {
        query = supabase
          .from('folders')
          .select('id , work:works(id, reviewer_id, transcriber_id)', { count: 'exact', head: true })
          .gt('completed_at', lastSeenCompleted.current.toUTCString())
          .is('deleted_at', null)
          .or(`transcriber_id.eq.${session.user.user_metadata.transcriber_id}, reviewer_id.eq.${session.user.user_metadata.transcriber_id}`, { referencedTable: 'works' })
          .not('work', 'is', null)
      } else {
        query = supabase
          .from('folders')
          .select('*', { count: 'exact', head: true })
          .gt('completed_at', lastSeenCompleted.current.toUTCString())
          .is('deleted_at', null)
      }

      const { count, error } = await query

      if (!error) {
        setCountNewCompleted(count)

        if (count > 0 && currentSection.current === 'completed') {
          resetLastSeenCompleted()
        }
      }
    }

    const interval = setInterval(() => {
      getLastCompleted()
    }, 60000)

    getLastCompleted()

    return () => {
      clearInterval(interval)
    }
  }, [])

  const tabsSection = useMemo(
    () =>
      sections?.map((item, index) => ({
        ...omit(item, ['icon']),
        label: (
          <Link onClick={(e) => {
            if (item.onClick) {
              e.preventDefault();
              e.stopPropagation();
              return item.onClick()
            }
            if (item.value === 'completed' || section.value === 'completed') {
              setCountNewCompleted(0)
              resetLastSeenCompleted()
            }
          }} key={index} to={item.path} className="universus-tab-link flex h-full items-center gap-2">
            <div className={clsx('universus-tab-link-hover flex items-center gap-2 py-2 px-3 rounded-md', item.value === section.value && 'active')}>
              <Text strong type={item.value === section.value ? 'primary' : 'secondary'}>
                <FontAwesomeIcon icon={item.icon} size="lg" />
              </Text>
              <Text strong type={'secondary'}>
                {!!item.valueFr && item.valueFr}
                {!item.valueFr && t(`${translateKey}.page.sections.${item.value}.label`)}
              </Text>

              {!!counts[item.value] && (settings.permissions['read-folders'] || !profile.universus) && counts[item.value] > 0 && (
                <Badge
                  showZero={false}
                  size="small"
                  overflowCount={99999}
                  count={counts[item.value]}
                  style={{
                    fontSize: 10,
                    padding: '0px 6px',
                    lineHeight: '15px',
                  }}
                />
              )}

              {item.value === 'completed' && countNewCompleted > 0 && (
                <div style={{
                  fontSize: 10,
                  color: 'white',
                  borderRadius: 10,
                  background: 'var(--ant-color-success-active)',
                  padding: '0px 6px',
                  lineHeight: '15px',
                }}>
                  {countNewCompleted}
                  {' '}nouveau{countNewCompleted > 1 ? 'x' : ''}
                </div>
              )}
            </div>
          </Link>
        ),
        key: item.value,
      })),
    [sections, countNewCompleted, counts]
  )

  useHotkeys(
    sections?.map((section, index) => `alt+${index + 1}`),
    ({ code }) => navigate(sections[code.match(/\d+/)[0] - 1].path),
    { enabled: settings.shortcuts_enabled }
  )

  return (
    <div
      style={style || {}}
      className={clsx(
        'flex items-center justify-between',
        !compact && 'border-0 border-b border-solid border-layout pr-3 pl-0'
      )}
    >
      {compact && (
        <div className='flex items-center gap-2'>
          <Label label={t(`${translateKey}.page.label`)} icon={icons.light} count={data.length} />
          {PageHeaderExtra}
        </div>
      )}

      {!compact && sections && <Tabs centered={centered} className="tabs-main h-full grow px-3 py-0" activeKey={section.value} items={tabsSection} />}
      {!sections && <div></div>}

      {<div className={clsx('flex items-center', !compact && 'py-3', compact && 'gap-2')}>
        {!action && <>
          {(!compact || isVisible || !!search.length) && (
            <PageSearch translateKey={translateKey} compact={compact} search={search} setSearch={setSearch} />
          )}

          {!compact && (
            <PageFilter translateKey={translateKey} columns={columns} filters={filters} setFilters={setFilters} />
          )}

          {!compact && <PageSort translateKey={translateKey} columns={columns} sorts={sorts} setSorts={setSorts} />}


          {settings.permissions && settings.permissions['create-folder'] && <Button
            type={compact ? 'primary' : 'primary'}
            size={compact ? 'small' : 'default'}
            className="ml-2"
            onClick={() => openForm()}
            icon={<FontAwesomeIcon icon={solid('plus')} />}
          >
            {t(translateKey + '.form.insert')}
          </Button>}
        </>}

        {action && <Button
          type={compact ? 'primary' : 'text'}
          size={compact ? 'small' : 'default'}
          className="ml-2"
          onClick={action.onClick}
        >
          {action.label}
        </Button>}
      </div>}
    </div >
  )
}
