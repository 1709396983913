import { light, solid } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Modal, theme, Typography } from "antd"
import { useEffect, useRef, useState } from "react"
import { useAuth } from "src/auth/Auth"
import { useLocation } from 'react-router-dom'
import clsx from "clsx"
import * as FavicoModule from 'favico.js/favico.js';
const Favico = FavicoModule.default || FavicoModule;

const { APP_TEAMS_URL } = import.meta.env

export default function Teams({ open, close }) {
    const { pathname } = useLocation()
    const { rocketchatSession, setRocketchatSession } = useAuth()
    const [rocketchatReady, setRocketchatready] = useState(false)
    const [tabCount, setTabCount] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [favicon, setFavicon] = useState(new Favico({
        animation: 'pop'
    }))
    const rocketchatIframe = useRef()

    const isOpen = pathname.includes('teams')

    const rocketchatSessionRef = useRef(rocketchatSession)

    useEffect(() => {
        if (Number.isInteger(rocketchatSession?.unread) && tabCount !== rocketchatSession.unread) {
            setTabCount(rocketchatSession.unread)

            setTimeout(() => {
                favicon.badge(rocketchatSession.unread)
            }, 100)
            
            if (navigator.setAppBadge) {
                navigator.setAppBadge(rocketchatSession.unread);
            }
        }

        rocketchatSessionRef.current = rocketchatSession
    }, [rocketchatSession])

    useEffect(() => {
        const handleMessage = (event) => {
            if (event.data.eventName === "startup") {
                setRocketchatready(true)
            }

            if (rocketchatSessionRef.current) {
                if (event.data.eventName === "unread-changed") {
                    setRocketchatSession({
                        ...rocketchatSessionRef.current,
                        unread: Number.isInteger(event.data.data) ? event.data.data : 0
                    })
                }

                if (event.data.eventName === "status-changed") {
                    setRocketchatSession({
                        ...rocketchatSessionRef.current,
                        status: event.data.data
                    })
                }

                if (event.data.eventName === "user-status-manually-set") {
                    setRocketchatSession({
                        ...rocketchatSessionRef.current,
                        status: event.data.data.id
                    })
                }
            }
        }

        window.addEventListener('message', handleMessage)

        return () => {
            window.removeEventListener('message', handleMessage)
        }
    }, [])

    useEffect(() => {
        if (rocketchatSession && rocketchatReady) {
            rocketchatIframe.current.contentWindow.postMessage({
                externalCommand: 'login-with-token',
                token: rocketchatSession.token
            }, '*')

            setTimeout(() => {
                setIsLoading(false)
            }, 500)
        }
    }, [rocketchatSession, rocketchatReady])

    return <div className={clsx("rounded-lg overflow-hidden w-full h-full absolute top-0 left-0", !isOpen && "hidden")}>
        {isLoading && <div className="absolute top-0 left-0 flex flex-col h-full grow gap-4 z-20 items-center justify-center w-full z-100 bg-base">
            <Typography.Text type="secondary" className="relative">
                <FontAwesomeIcon icon={light('messages')} size={"5x"} />
                <FontAwesomeIcon
                    style={{ right: -24, bottom: 0 }}
                    className="fa-spinner text-primary absolute opacity-60"
                    icon={solid('spinner')}
                    size="lg"
                />
            </Typography.Text>
            <Typography.Text type="secondary">
                <strong>Teams</strong>
            </Typography.Text>
        </div>}
        <iframe ref={rocketchatIframe} src={APP_TEAMS_URL + '?layout-embedded'} style={{ border: 'none', width: '100%', height: '100%' }} />
    </div>
}