import { useEffect, useMemo, useState } from 'react'
import { find } from 'lodash'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Button, DatePicker, Dropdown, Input, Popover, Select, Switch, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

const { Text } = Typography

export default function PageFilters({ translateKey, size, columns, filters, setFilters, type = 'button', placement = 'bottomRight' }) {
  const { t } = useTranslation()
  const [isPanelOpen, setPanelOpen] = useState(false)

  return (
    <Popover
      open={isPanelOpen}
      onOpenChange={(next) => setPanelOpen(next)}
      content={() => <FilterPanel translateKey={translateKey} columns={columns} sorts={filters} setSorts={setFilters} />}
      fresh={false}
      trigger="click"
      arrow={false}
      placement={placement}
    >
      {type === 'button' && (
        <Button
          size={size}
          onClick={() => setPanelOpen(!isPanelOpen)}
          type="text"
          className={clsx(!!filters.length && 'text-primary')}
        >
          {t('page.filter.button')}
        </Button>
      )}
      {type === 'text' && (
        <div onClick={() => setPanelOpen(!isPanelOpen)} className="w-full">
          <Text>{t('page.filter.button')}</Text>
        </div>
      )}
    </Popover>
  )
}

function FilterPanel({ columns, sorts, setSorts, translateKey }) {
  const { t } = useTranslation()
  const [updateSorts, setUpdateSorts] = useState(sorts)
  const [toApply, setToApply] = useState(false)

  useEffect(() => {
    setUpdateSorts(sorts)
  }, [sorts])

  const items = useMemo(
    () =>
      columns.filter(column => column.filterOption && !column.filterOption.disable).map((column) => ({
        key: column.field,
        label: t(`${translateKey}.fields.${column.field}`),
        field: column.field,
        icon: column.headerComponentParams?.icon,
        filterOption: column.filterOption
      })),
    [columns]
  )

  const addSort = (itemKey) => {
    const item = find(items, { key: itemKey })
    setToApply(true)
    setUpdateSorts([
      ...updateSorts,
      {
        ...item,
        value: item.filterOption.defaultValue
      },
    ])
  }

  const updateSort = (key, value) => {
    setToApply(true)
    setUpdateSorts(updateSorts.map(s => s.key === key ? ({ ...s, value }) : s))
  }

  const deleteSort = (item) => {
    setToApply(true)
    setUpdateSorts(updateSorts.filter((sort) => sort.field !== item.field))
  }

  const handleDragEnd = (result) => {
    if (!result.destination) return
    const updatedSorts = [...updateSorts]
    const [reorderedSort] = updatedSorts.splice(result.source.index, 1)
    updatedSorts.splice(result.destination.index, 0, reorderedSort)

    setUpdateSorts(updatedSorts)
  }

  const apply = () => [setToApply(false), setSorts(updateSorts)]

  const sortItems = items
    .filter((item) => !updateSorts.find((sort) => sort.field === item.field))
    .map((item) => ({ ...item, icon: <FontAwesomeIcon fixedWidth icon={item.icon} /> }))

  return (
    <>
      {!!updateSorts.length && (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {updateSorts.map((item, index) => (
                  <Draggable key={item.field} draggableId={item.field} index={index}>
                    {(provided) => (
                      <div
                        className="flex items-center justify-between"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className="inline-flex items-center gap-1.5 flex-grow">
                          <FontAwesomeIcon icon={solid('grip-dots-vertical')} />
                          <FontAwesomeIcon icon={item.icon} fixedWidth />
                          <Text strong>{item.label}</Text>
                          <div className='flex-grow flex'>
                            {item.filterOption?.type === 'select' && item.filterOption?.valuesHook && <SelectFilter
                              {...item.filterOption}
                              value={item.value}
                              onChange={(value) => updateSort(item.key, value)}
                            />}
                            {item.filterOption?.type === 'text' && <Input
                              className='w-full'
                              {...item.filterOption}
                              value={item.value}
                              onChange={(e) => updateSort(item.key, e.target.value)}
                            />}
                            {item.filterOption?.type === 'boolean' && <Switch
                              {...item.filterOption}
                              value={item.value}
                              onChange={(e) => updateSort(item.key, e)}
                            />}
                            {item.filterOption?.type === 'date' && <DatePicker className='w-full' onChange={(value) => updateSort(item.key, value)} value={item.value} format="DD/MM/YYYY" />
                            }
                            {item.filterOption?.type === 'select' && !item.filterOption?.valuesHook && <Select
                              filterOption={(input, option) =>
                                (option?.label ?? '')
                                  .normalize("NFD")
                                  .replace(/[\u0300-\u036f]/g, "")
                                  .toLowerCase()
                                  .includes(
                                    input
                                      .normalize("NFD")
                                      .replace(/[\u0300-\u036f]/g, "")
                                      .toLowerCase()
                                  )
                              }
                              size="small"
                              mode="tags"
                              value={item.value}
                              onChange={(value) => updateSort(item.key, value)}
                              className='w-full'
                              showSearch={true}
                              options={item.filterOption.values.map(v => ({ ...v, label: t(v.label) }))}
                            />}
                          </div>
                        </div>
                        <div className="inline-flex items-center gap-1.5">
                          <Button
                            onClick={() => deleteSort(item)}
                            icon={<FontAwesomeIcon icon={light('trash')} />}
                            type="text"
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}

      {!updateSorts.length && (
        <>
          <Text strong type="secondary">
            {t('page.filter.empty')}
          </Text>
          <br />
          <Text type="secondary">{t('page.filter.empty-description')}</Text>
        </>
      )}

      <div className="mt-4 flex items-end justify-between gap-4">
        <Dropdown
          menu={{ items: sortItems, onClick: ({ key }) => addSort(key) }}
          trigger={['click']}
          placement="bottomRight"
        >
          <Button
            block
            disabled={!sortItems.length}
            type="text"
            className="inline-flex items-center justify-center gap-3"
          >
            {t('page.filter.pick')}
            <FontAwesomeIcon icon={regular('chevron-down')} size="sm" />
          </Button>
        </Dropdown>
        <Button disabled={!toApply} onClick={apply}>
          {t('page.filter.apply')}
        </Button>
      </div>
    </>
  )
}

function SelectFilter({ valuesHook, valuesLabel, value, onChange }) {
  const { data } = valuesHook()

  return <Select
    size="small"
    mode="tags"
    className='w-full'
    showSearch={true}
    value={value}
    onChange={onChange}
    options={data.map(valuesLabel)}
    filterOption={(input, option) =>
      (option?.label ?? '')
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .includes(
          input
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
        )
    }
  />
}