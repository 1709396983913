import { omit } from 'lodash'

import clsx from 'clsx'
import { Avatar as AvatarAnt, Badge } from 'antd'
import { useEffect, useState } from 'react'
import { UserOutlined } from '@ant-design/icons';
import logo from "src/assets/universus-avatar.png"

export default function Avatar(props) {
  const src = props.universus ? logo : props.src || props.profile?.avatar || props.profile?.image
  const name = (props.profile?.first_name || props.profile?.last_name)

  return (
    <div className="relative">
      <AvatarAnt
        src={src}
        icon={!src && (!name || !name.length) && <UserOutlined />}
        {...omit(props, ['border', 'indicator', 'smallIndicator', 'universus'])}
        className={clsx(
          'inline-flex cursor-pointer select-none items-center justify-center bg-primary text-primary',
          props.border && 'border-1 box-border border-solid border-base',
          props.className
        )}
      >
        {!src && name?.charAt(0).toUpperCase()}
      </AvatarAnt>
      {props.indicator && (
        <div
          className={clsx(
            'bg-success absolute z-30 rounded-full',
            props.smallIndicator && 'bottom-[3px] right-[-5px] h-1 w-1',
            !props.smallIndicator && 'bottom-[2px] right-[2px] h-2 w-2'
          )}
        ></div>
      )}
    </div>
  )
}
