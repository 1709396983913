import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Tag } from 'antd'

export default function CellTag({ value }) {
  const { t } = useTranslation()

  return value && (
    <div>
      <Tag icon={<FontAwesomeIcon className="mr-1.5" icon={value.icon} />} color={value.color}>
        {t(value.label)}
      </Tag>
    </div>
  )
}
