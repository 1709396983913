import SettingsAccount from 'src/settings/SettingsAccount'
import SettingsSettings from 'src/settings/SettingsSettings'
import SettingsLanguage from 'src/settings/SettingsLanguage'
import SettingsUsers from 'src/settings/SettingsUsers'
import SettingsStripe from 'src/settings/SettingsStripe'
import { useTranslation } from 'react-i18next'

import { solid, light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography } from 'antd'

const { Title, Text } = Typography

export const menuSettings = [
  {
    section: 'profile',
    key: 'my-account',
    icon: light('user'),
    filter: (session) => true,
    element: <SettingsAccount />,
  },
  {
    section: 'profile',
    filter: (meta, settings) => meta.universus && settings.permissions?.admin,
    key: 'companies',
    icon: light('hospital'),
    element: <SettingsUsers type="companies" />,
  },
  {
    section: 'profile',
    key: 'administrators',
    filter: (meta, settings) => (meta.universus || meta.administrator_id) && settings.permissions?.admin,
    icon: light('user-headset'),
    element: <SettingsUsers type="administrators" />,
  },
  {
    section: 'profile',
    key: 'doctors',
    filter: (meta, settings) => (meta.universus || meta.administrator_id) && settings.permissions?.admin,
    icon: light('user-doctor'),
    element: <SettingsUsers type="doctors" />,
  },
  {
    section: 'profile',
    filter: (meta, settings) => meta.universus && settings.permissions?.admin,
    key: 'specialities',
    icon: light('shapes'),
    element: <SettingsUsers type="specialities" />,
  },
  {
    section: 'profile',
    filter: (meta, settings) => meta.universus && settings.permissions?.admin,
    key: 'clients',
    icon: light('star'),
    element: <div></div>,
  },  
  {
    section: 'profile',
    filter: (meta, settings) => meta.universus && settings.permissions?.admin,
    key: 'transcribers',
    icon: light('people-group'),
    element: <SettingsUsers type="transcribers" />,
  },
]

function WIP() {
  const { t } = useTranslation()

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-4">
      <Text>
        <FontAwesomeIcon icon={regular('microchip')} size="5x" />
      </Text>
      <Title>{t('wip')}</Title>
    </div>
  )
}
