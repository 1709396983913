import { duotone, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Empty, Typography } from 'antd'

const { Text } = Typography

export default function EmptySearch({ compact, search }) {
  return (
    <div className="box-border flex w-full grow items-center justify-center p-6">
      <Empty
        image={null}
        imageStyle={{ display: 'none' }}
        description={
          <div className="flex flex-col gap-6">
            <Text type="secondary">
              <FontAwesomeIcon icon={duotone('magnifying-glass')} size={compact ? '3x' : '5x'} />
            </Text>
            <Text type="secondary">
              No client matches the specified search terms.
              <br />
              <div className="flex items-center gap-2 justify-center mt-2">
                <FontAwesomeIcon icon={solid('quote-left')} size="1x" />
                <strong className="text-lg">{search}</strong>
                <FontAwesomeIcon icon={solid('quote-right')} size="1x" />
              </div>
            </Text>
          </div>
        }
      />
    </div>
  )
}
