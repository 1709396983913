import { useContext, useState } from 'react'

import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Dropdown, Popconfirm, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { useUI } from 'src/layout/UI'
import { PageContext } from 'src/@components/page/Page'

const { Text } = Typography

export default function FormMenu({ placement = "bottomRight", size = "default", onClick, closeForm, onClose, values }) {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { messageApi } = useUI()
  const { menus, translateKey, remove } = useContext(PageContext)

  const close = () => setIsOpen(false)

  return (
    <Dropdown
      onOpenChange={(nextOpen, { source }) => {
        if (source === 'trigger') {
          setIsOpen(nextOpen)
          if (onClose) {
            onClose()
          }
        }
      }}
      open={isOpen}
      menu={{
        items: [
          {
            label: (
              <Popconfirm
                arrow={false}
                rootClassName="elevated pr-4"
                placement={'left'}
                title={t(`${translateKey}.form.remove.primary`)}
                description={t(`${translateKey}.form.remove.secondary`)}
                okText={t('yes')}
                cancelText={t('no')}
                onConfirm={() => [setIsOpen(false), setIsLoading(true), remove(values, {
                  onSuccess: () => {
                    setIsLoading(false)

                    if (closeForm) {
                      closeForm()
                    }

                    if (onClose) {
                      onClose()
                    }
                  }
                })]}
                onCancel={() => [setIsOpen(false), onClose && onClose()]}
              >
                <div className="flex items-center gap-2">
                  <FontAwesomeIcon fixedWidth icon={light('trash')} />
                  <Text>{t('form.menu.remove')}</Text>
                </div>
              </Popconfirm>
            ),
          },
          ...menus.filter(menu => menu.more && menu.filter(values)).map(menu => ({ ...menu.item, label: t(menu.item.label), onClick: () => menu.item.onClick(values, messageApi, close) }))
        ],
      }}
      trigger={['click']}
      placement={placement}
    >
      <Button
        onClick={(e) => [setIsOpen(!isOpen), onClick && onClick()]}
        type="default"
        size={size}
        loading={isLoading}
        icon={<FontAwesomeIcon icon={regular('ellipsis')} />}
      ></Button>
    </Dropdown>
  )
}
