import { Typography } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRef } from 'react'

const { Text } = Typography

export default function SettingsItem({ icon, label, description, actions }) {
  const ref = useRef()
  const onClick = () => {
    if (ref.current) {
      const input = ref.current.querySelector('button, input, select')
      if (input) {
        input.focus()
        input.click()
      }
    }
  }

  return (
    <div onClick={onClick} ref={ref} className="cursor-pointer flex items-center gap-4">
        <FontAwesomeIcon size="2x" fixedWidth icon={icon} />
        <div className="flex flex-col grow">
            <Text>{label}</Text>
            <Text className='text-xs' type="secondary">{description}</Text>
        </div>
        <div>
          {actions}
        </div>
    </div>
  )
}
