import { duotone, light, regular, solid, thin } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Dropdown, Input, List, Modal, Slider, Tag, Tabs, Tooltip, Typography, theme, Badge } from "antd"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import Avatar from "src/@components/Avatar"
import Label from "src/@components/Label"
import { useLocation, useParams, useNavigate, Link } from 'react-router-dom'
import Textarea from "src/@components/form/Textarea"
import PageHeader from "src/@components/page/PageHeader"
import { supabase } from "src/supabase"
import logo from "src/assets/universus-black.png"
import { useProfile, useTranscriber } from "src/profiles/ProfilesApi"
import clsx from "clsx"
import Form from "src/@components/form/Form"
import FoldersForm, { FoldersFormInitialValues } from "src/folders/FoldersForm"
import { useFolder, useRemoveFolder, useUpdateFolder } from "src/folders/FoldersApi"
import { useSettings } from "src/settings/SettingsApi"
import { useQueryClient } from "@tanstack/react-query"
import Transcription from "src/transcription/Transcription"
import Folder from "src/transcription/Folder"
import Files from "src/transcription/Files"
import Chat from "src/transcription/Chat"
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels"
import { useUI } from "src/layout/UI"
import _ from "lodash"

const { defaultAlgorithm, darkAlgorithm, useToken } = theme

export default function TranscriptionPage({ compact }) {
  const [showLeft, setShowLeft] = useState(!localStorage.getItem('hideLeft'))
  const [pane, setPane] = useState('right')
  const [left, setLeft] = useState('folder')
  const [files, setFiles] = useState(false)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { backUrl } = useUI()
  const tokens = useToken()
  const { data: transcriber } = useTranscriber()

  const { data: settings } = useSettings()
  const { data: profile } = useProfile()
  const { folder: id } = useParams()

  const [loading, setLoading] = useState(false)
  const [folder, setFolder] = useState(false)
  const [chatReview, setChatReview] = useState(null)
  const [reviewLoading, setReviewLoading] = useState(false)
  const [dictation, setDictation] = useState(false)
  const [initialValues, setInitialValues] = useState(false)

  const openChatForReview = (review) => {
    if (review) {
      setLeft('chatbot')
      setChatReview(review)
    } else {
      setChatReview(null)
      setLeft('chatbot')
      setReviewLoading(false)
    }
  }

  const [transcription, setTranscription] = useState(null)

  useEffect(() => {
    if (folder) {
      const correction = localStorage.getItem('correction') ? JSON.parse(localStorage.getItem('correction')) : false

      if (correction && parseInt(correction.id) === parseInt(folder.id)) {
        setTranscription(correction.text)
      } else if (folder.work.correction) {
        const text = folder.work.correction.result.trim() || ''
        setTranscription(text)
      }
    }
  }, [folder])

  const saveCorrection = (text) => {
    localStorage.setItem('correction', JSON.stringify({
      id: folder.id,
      text
    }))
  }

  useEffect(() => {
    if (transcription) {
      debouncedSave(transcription)
    }
  }, [transcription])

  const debouncedSave = useCallback(
    _.debounce((newText) => {
      saveCorrection(newText)
    }, 1000),
    [folder]
  )

  useEffect(() => {
    if (!showLeft) {
      setPane('right')
      localStorage.setItem('hideLeft', true)
    } else {
      localStorage.removeItem('hideLeft')
    }
  }, [showLeft])

  useEffect(() => {
    if (folder) {
      fetchFiles()
    }
  }, [folder.folder_id])

  useEffect(() => {
    if (folder && settings?.permissions && transcriber) {
      if (!settings.permissions['read-folders']) {
        const user = folder.work.translater || folder.work.reviewer || folder.work.transcriber
        if (!user || user.id !== transcriber.id) {
          if (!transcriber.current || transcriber.current.id !== folder.id) {
            navigate('/available')
          }
        }
      }
    }
  }, [folder, settings, transcriber])

  const fetchFiles = async (silent = false) => {
    if (!silent) {
      setFiles(false)
    }

    supabase.storage.from('folders').list(`${folder.company.id}/${folder.folder_id}`)
      .then(async ({ data, error }) => {
        setFiles(data.filter(file => {
          if ([
            folder.dictation_path?.split('/').pop(),
            folder.report_path?.split('/').pop(),
          ].includes(file.name)
            || !file.id
            || file.name.toLowerCase().includes('dictee')
            || file.name.toLowerCase().endsWith('dss')
            || file.name.toLowerCase().endsWith('ds2')
            || file.name.toLowerCase().endsWith('m4a')
            || file.name.toLowerCase().endsWith('mp3')) {
            return false
          }

          return true
        }))


        if (folder.dictation_path) {
          const {
            data: { signedUrl },
          } = await supabase.storage.from('folders').createSignedUrl(`${folder.dictation_path}`, 60)

          setDictation(signedUrl)
        }
      })
      .catch(() => setFiles([]))
  }

  useEffect(() => {
    const fetchFolder = async () => {
      setLoading(true)
      const { data, error } = await useFolder(id)
      if (data) {
        setFolder(data)
        setInitialValues(FoldersFormInitialValues({ profile, values: data }))
      }
      setLoading(false)
    }

    if (id && !folder) {
      let folders = [
        ...(queryClient.getQueryData(['folders', 'inprogress']) || []),
        ...(queryClient.getQueryData(['folders', 'completed']) || []),
        ...(queryClient.getQueryData(['folders', 'available']) || []),
      ]


      let findFolder = folders.find(f => f.id === parseInt(id))
      if (findFolder) {
        setFolder(findFolder)
        setInitialValues(FoldersFormInitialValues({ profile, values: findFolder }))
      } else {
        fetchFolder()
      }

    }
  }, [id])

  const goBack = () => {
    if (backUrl === 'back') {
      window.history.back()
    } else {
      navigate(backUrl)
    }
  }

  const tabsSection = useMemo(
    () =>
      [
        {
          value: 'back',
          label: ' ',
          hide: settings?.permissions && !settings.permissions['read-folders'],
          onClick: () => goBack(),
          icon: solid('arrow-left')
        },
        {
          value: 'folder',
          label: 'Informations',
          onClick: () => setLeft('folder'),
          icon: duotone('file-lines')
        },
        {
          value: 'files',
          label: 'Documents',
          onClick: () => setLeft('files'),
          icon: duotone('paperclip-vertical')
        },
        {
          value: 'chatbot',
          label: 'Dr Wav',
          onClick: () => setLeft('chatbot'),
          icon: duotone('chart-radar')
        },
      ].filter(s => !s.hide).map((item, index) => ({
        label: (
          <Link onClick={(e) => {
            if (item.onClick) {
              setPane('left')
              e.preventDefault()
              e.stopPropagation()
              
              if (reviewLoading) {
                return false
              }

              return item.onClick()
            }
          }} key={index} to={item.path} className="universus-tab-link flex h-full items-center gap-2">
            <div className={clsx('flex items-center gap-2 rounded-md', item.value !== "back" && "universus-tab-link-hover py-2 px-3", item.value === left && 'active')}>
              {item.value !== 'back' && <>
                <Typography.Text strong type={item.value === left ? 'primary' : 'secondary'}>
                  <FontAwesomeIcon icon={item.icon} size="lg" />
                </Typography.Text>
              </>}

              {item.value === 'back' && <Button type="text" shape="circle" icon={<FontAwesomeIcon icon={item.icon} size="lg" />} />}

              <Typography.Text strong type={'secondary'} style={reviewLoading && item.value !== 'chatbot' ? { opacity: 0.5, cursor: 'not-allowed' } : {}}>
                {item.label}
              </Typography.Text>

              {item.value === 'files' && files.length > 0 && (
                <Badge
                  showZero={false}
                  size="small"
                  overflowCount={99999}
                  count={files.length}
                  style={{
                    fontSize: 10,
                    padding: '0px 6px',
                    lineHeight: '15px',
                  }}
                />
              )}

              {item.value === 'chatbot' && <Tag bordered={false} style={{
                fontSize: "10px",
                margin: 0,
                padding: "0px 8px",
                height: "18px",
                lineHeight: "18px"
              }} size="small" color="purple">beta</Tag>}
            </div>
          </Link>
        ),
        key: item.value,
      })),
    [files, settings, reviewLoading]
  )

  if (loading) {
    return <div className="flex flex-col gap-4 relative items-center justify-center w-full">
      <Typography.Text type="secondary" className="relative">
        <FontAwesomeIcon icon={solid('folder-open')} size={"5x"} />
        <FontAwesomeIcon
          style={{ right: -24, bottom: 0 }}
          className="fa-spinner text-primary absolute opacity-60"
          icon={solid('spinner')}
          size="lg"
        />
      </Typography.Text>
      <Typography.Text type="secondary">
        <strong>Chargement du dossier...</strong>
      </Typography.Text>
    </div>
  }

  return (
    <>
      <PanelGroup autoSaveId="transcription" direction="horizontal">
        {showLeft && <>
          <Panel defaultSize={40} order={1}>
            <div className="relative w-full h-full">
              <div id={"modalMount"} onClick={() => setPane('left')} className="box-border w-full h-full absolute top-0 left-0 min-h-full rounded-lg bg-base shadow-md grow flex flex-col" style={{ boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)", transition: 'ease-in 0.2s all', border: `2px solid ${tokens.token.colorBgBase}`, borderColor: pane === 'left' ? tokens.token.colorBorder : 'transparent' }}>
                <div
                  style={{ height: 66 }}
                  className={clsx(
                    'shrink-0 flex items-center justify-between',
                    'border-0 border-b border-solid border-layout pr-3 pl-0'
                  )}
                >
                  {<Tabs
                    className="tabs-main h-full grow px-3 py-0"
                    activeKey={left}
                    items={tabsSection}
                  />}

                  {<div className={clsx('flex items-center', !compact && 'py-3', compact && 'gap-2')}>
                    <Button style={{ opacity: 0.5 }} onClick={() => setShowLeft(false)} type="text" shape="circle" icon={<FontAwesomeIcon icon={solid('arrow-left-to-bracket')} />} />
                  </div>}
                </div >


                {folder && <div className="flex flex-col grow relative overflow-auto scroll">
                  {left === 'folder' && initialValues && <Folder initialValues={initialValues} folder={folder} setFolder={setFolder} />}
                  {left === 'files' && <Files files={files} fetchFiles={fetchFiles} folder={folder} />}
                  {left === 'chatbot' && <Chat setReviewLoading={setReviewLoading} reviewLoading={reviewLoading} review={chatReview} transcription={transcription} folder={folder} />}
                </div>}
              </div>
            </div>
          </Panel>

          <PanelResizeHandle>
            <div style={{ margin: '0px 2px' }} className="box-border flex flex-col items-center h-full justify-center"><Typography.Text className="grip" type="secondary"><FontAwesomeIcon icon={solid("grip-dots-vertical")} /></Typography.Text></div>
          </PanelResizeHandle>
        </>}
        <Panel defaultSize={60} order={2}>
          <div onClick={() => setPane('right')} className="box-border w-full h-full rounded-lg bg-base shadow-md flex flex-col" style={{ boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)", transition: 'ease-in 0.2s all', border: `2px solid ${tokens.token.colorBgBase}`, borderColor: pane === 'right' ? tokens.token.colorBorder : 'transparent' }}>
            {folder && <>
              <Transcription reviewLoading={reviewLoading} transcription={transcription} setTranscription={setTranscription} openChatForReview={openChatForReview} loadParent={setLoading} dictation={dictation} goBack={goBack} showLeft={showLeft} setShowLeft={setShowLeft} folder={folder} />
            </>}
          </div>
        </Panel>
      </PanelGroup>
    </>
  )
}