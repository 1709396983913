import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Typography } from 'antd'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { PageContext } from 'src/@components/page/Page'

const { Text } = Typography

export default function FormHeader({ icons, onClose, isUpdate }) {
  const { t } = useTranslation()
  const { translateKey } = useContext(PageContext)

  return (
    <div className="relative bottom-[1px] flex items-center justify-between border-0 border-b border-solid border-layout bg-layout py-2 pl-4 pr-1">
      <Text type="secondary" className="inline-flex cursor-pointer select-none items-center gap-2">
        <FontAwesomeIcon icon={icons.solid} />
        {t(`${translateKey}.form.${isUpdate ? 'update' : 'insert'}`)}
      </Text>
      <Button onClick={onClose} type="text">
        <span className='flex items-center gap-2' style={{ display: 'flex' }}>
          {t(`cancel`)}
        </span>
      </Button>
    </div>
  )
}
