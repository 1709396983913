import { useMemo } from 'react'
import { useProfile } from 'src/profiles/ProfilesApi'

import { menuSettings } from 'src/menus'
import { Typography, Tabs, Divider } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Avatar from 'src/@components/Avatar'
import { useTranslation } from 'react-i18next'
import { useSettings } from 'src/settings/SettingsApi'
import { useAuth } from 'src/auth/Auth'

const { Text } = Typography

export default function SettingsMenu({ section, setSection }) {
  const { session } = useAuth()
  const { data: profile } = useProfile()
  const { data: settings } = useSettings()
  const { t } = useTranslation()

  const itemsProfile = useMemo(() => menuSettings.filter(setting => setting.section === 'profile').filter(setting => setting.filter(session.user.user_metadata, settings)).map(setting => ({ ...setting, label: t('settings.' + setting.key + '.menu'), icon: <FontAwesomeIcon fixedWidth size='lg' icon={setting.icon} /> })), [settings.lang])

  return (
    <div className="box-border flex w-64 flex-col bg-layout pb-4 min-h-[550px]">
      <div className="flex items-center gap-3 p-4">
        <Avatar profile={profile} size={40} />
        <div className="flex flex-col overflow-hidden">
          <Text strong>
            {profile.first_name} {profile.last_name}
          </Text>
          <Text type="secondary" style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
          }}>{profile.email}</Text>
        </div>
      </div>
      <Tabs
        onTabClick={(tab) => setSection(tab)}
        tabBarStyle={{ width: '100%' }}
        activeKey={section}
        tabPosition={'left'}
        items={itemsProfile}
      />
      {/* <Text className="px-4 py-2" strong type="secondary">
        Workspace
      </Text>
      <Tabs
        onTabClick={(tab) => setSection(tab)}
        tabBarStyle={{ width: '100%' }}
        defaultActiveKey={null}
        activeKey={section}
        tabPosition={'left'}
        items={itemsWorkspace}
      />
      <Divider />
      <Tabs
        onTabClick={(tab) => setSection(tab)}
        tabBarStyle={{ width: '100%' }}
        activeKey={section}
        tabPosition={'left'}
        items={itemsExtra}
      /> */}
    </div>
  )
}
