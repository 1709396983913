import { useEffect, useMemo, useState } from 'react'
import { find } from 'lodash'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Button, Dropdown, Popover, Switch, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

const { Text } = Typography

export default function PageSort({ translateKey, size, columns, sorts, setSorts, type = 'button', placement = 'bottomRight' }) {
  const { t } = useTranslation()
  const [isPanelOpen, setPanelOpen] = useState(false)

  return (
    <Popover
      open={isPanelOpen}
      onOpenChange={(next) => setPanelOpen(next)}
      content={() => <SortPanel translateKey={translateKey} columns={columns} sorts={sorts} setSorts={setSorts} />}
      fresh={false}
      trigger="click"
      arrow={false}
      placement={placement}
    >
      {type === 'button' && (
        <Button
          size={size}
          onClick={() => setPanelOpen(!isPanelOpen)}
          type="text"
          className={clsx(!!sorts.length && 'text-primary')}
        >
          {t('page.sort.button')}
        </Button>
      )}
      {type === 'text' && (
        <div onClick={() => setPanelOpen(!isPanelOpen)} className="w-full">
          <Text>{t('page.sort.button')}</Text>
        </div>
      )}
    </Popover>
  )
}

function SortPanel({ translateKey, columns, sorts, setSorts }) {
  const { t } = useTranslation()
  const [updateSorts, setUpdateSorts] = useState(sorts)
  const [toApply, setToApply] = useState(false)

  useEffect(() => {
    setUpdateSorts(sorts)
  }, [sorts])

  const items = useMemo(
    () =>
      columns.map((column) => ({
        key: column.field,
        label: t(`${translateKey}.fields.${column.field}`),
        field: column.field,
        sortField: column.sortField,
        icon: column.headerComponentParams?.icon,
      })),
    [columns]
  )

  const addSort = (itemKey) => {
    setToApply(true)
    setUpdateSorts([
      ...updateSorts,
      {
        ...find(items, { key: itemKey }),
        ascending: true,
      },
    ])
  }

  const deleteSort = (item) => {
    setToApply(true)
    setUpdateSorts(updateSorts.filter((sort) => sort.field !== item.field))
  }

  const toggleAscending = (item) => {
    setToApply(true)
    setUpdateSorts(
      updateSorts.map((sort) => (sort.field === item.field ? { ...sort, ascending: !sort.ascending } : sort))
    )
  }

  const handleDragEnd = (result) => {
    if (!result.destination) return
    const updatedSorts = [...updateSorts]
    const [reorderedSort] = updatedSorts.splice(result.source.index, 1)
    updatedSorts.splice(result.destination.index, 0, reorderedSort)

    setUpdateSorts(updatedSorts)
  }

  const apply = () => [setToApply(false), setSorts(updateSorts)]

  const sortItems = items
    .filter((item) => !updateSorts.find((sort) => sort.field === item.field))
    .map((item) => ({ ...item, icon: <FontAwesomeIcon fixedWidth icon={item.icon} /> }))

  return (
    <>
      {!!updateSorts.length && (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {updateSorts.map((item, index) => (
                  <Draggable key={item.field} draggableId={item.field} index={index}>
                    {(provided) => (
                      <div
                        className="flex items-center justify-between"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className="inline-flex items-center gap-1.5">
                          <FontAwesomeIcon icon={solid('grip-dots-vertical')} />
                          {/* @TRANSLATE */}
                          <Text type="secondary">sort by</Text>
                          <FontAwesomeIcon icon={item.icon} fixedWidth />
                          <Text strong>{item.label}</Text>
                        </div>
                        <div className="inline-flex items-center gap-1.5">
                          <Text>{t('page.sort.ascending')}</Text>
                          <Switch onClick={() => toggleAscending(item)} value={item.ascending} size="small" />
                          <Button
                            onClick={() => deleteSort(item)}
                            icon={<FontAwesomeIcon icon={light('trash')} />}
                            type="text"
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}

      {!updateSorts.length && (
        <>
          <Text strong type="secondary">
          {t('page.sort.empty')}
          </Text>
          <br />
          <Text type="secondary">{t('page.sort.empty-description')}</Text>
        </>
      )}

      <div className="mt-4 flex items-end justify-between gap-4">
        <Dropdown
          menu={{ items: sortItems, onClick: ({ key }) => addSort(key) }}
          trigger={['click']}
          placement="bottomRight"
        >
          <Button
            block
            disabled={!sortItems.length}
            type="text"
            className="inline-flex items-center justify-center gap-3"
          >
            {t('page.sort.pick')}
            <FontAwesomeIcon icon={regular('chevron-down')} size="sm" />
          </Button>
        </Dropdown>
        <Button disabled={!toApply} onClick={apply}>
          {t('page.sort.apply')}
        </Button>
      </div>
    </>
  )
}
