import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown, Typography } from 'antd'
import { find } from 'lodash'
import { useContext } from 'react'
import { PageContext } from 'src/@components/page/Page'

const { Text } = Typography

export default function CellHeader({ column, api }) {
  const { headerName, headerComponentParams, field, sortField } = column.colDef
  const { sorts, setSorts } = useContext(PageContext)

  const quickSort = (ascending) => {
    const sortItem = {
      icon: headerComponentParams.icon,
      label: headerName,
      field,
      sortField,
      ascending,
    }

    if (find(sorts, { field: sortItem.field })) {
      setSorts((sorts) => sorts.map((sort) => (sort.field === sortItem.field ? sortItem : sort)))
    } else {
      setSorts((sorts) => [...sorts, sortItem])
    }
  }

  const pin = () => api.setColumnPinned(field, column.pinned ? null : 'left')

  if (!headerName) {
    return null
  }

  //@TRANSLATE
  return (
    <Dropdown
      menu={{
        items: [
          {
            label: 'Sort ascending',
            icon: <FontAwesomeIcon fixedWidth icon={light('arrow-up')} />,
            onClick: () => quickSort(true),
          },
          {
            label: 'Sort descending',
            icon: <FontAwesomeIcon fixedWidth icon={light('arrow-down')} />,
            onClick: () => quickSort(false),
          },
          {
            label: `${column.pinned ? 'Unpin' : 'Pin'} column`,
            icon: <FontAwesomeIcon fixedWidth icon={light('thumbtack')} />,
            onClick: () => pin(),
          },
        ],
      }}
      trigger={['click']}
      placement="bottom"
    >
      <div className="box-border inline-flex w-full gap-1.5 overflow-hidden text-ellipsis">
        {column.colDef.headerComponentParams?.icon && (
          <Text type="secondary">
            <FontAwesomeIcon icon={column.colDef.headerComponentParams.icon} />
          </Text>
        )}

        <Text type="secondary">{headerName}</Text>
      </div>
    </Dropdown>
  )
}
