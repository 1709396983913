import { useState } from 'react'

import { useUI } from 'src/layout/UI'

import Label from 'src/@components/Label'
import { Button, Form, Modal, Input } from 'antd'
import { useTranslation } from 'react-i18next'

import { supabase } from 'src/supabase'
export default function SettingsPassword({ close }) {
  const { t } = useTranslation()
  const { messageApi } = useUI()
  const [isLoading, setLoading] = useState(false)


  const onFinish = async (values) => {
    setLoading(true)

    const { error } = await supabase.auth.updateUser({
      password: values.password
    })

    setLoading(false)

    if (error) {
      messageApi.open({
        type: 'error',
        content: error.message,
        duration: 4,
      })
    } else {
      messageApi.open({
        type: 'success',
        content: t("settings.my-account.password-success"),
        duration: 4,
      })
      close()
    }
  }

  return (
    <Modal
      styles={{ content: { padding: 0, background: 'transparent' } }}
      transitionName="zoom"
      closeIcon={false}
      footer={null}
      centered
      open={true}
      onCancel={close}
      width={300}
    >
      <div className="flex w-full overflow-hidden rounded-xl bg-layout p-6 gap-6 flex-col">
        <Form disabled={isLoading} onFinish={onFinish} className="flex w-full flex-col gap-6">
          <Label label={t("settings.my-account.password")}>
            <Form.Item
              rules={[
                { required: true, message: t("rules.password-required") },
                { min: 6, message: t("rules.password-length") },
              ]}
              name="password"
            >
              <Input.Password placeholder="••••••••" />
            </Form.Item>
          </Label>
          <Label label={t("settings.my-account.confirm-password")}>
            <Form.Item
              rules={[
                { required: true, message: t("rules.password-required") },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error(t("settings.my-account.password-match")))
                  },
                }),
              ]}
              name="repeat"
            >
              <Input.Password placeholder="••••••••" />
            </Form.Item>
          </Label>
          <Button loading={isLoading} htmlType="submit" type="primary">
            {t("settings.my-account.change-password")}
          </Button>
        </Form>
      </div>
    </Modal>
  )
}
