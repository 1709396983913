import { useState } from 'react'
import { find } from 'lodash'

import { useProfile } from 'src/profiles/ProfilesApi'

import { menuSettings } from 'src/menus'
import SettingsMenu from 'src/@components/settings/SettingsMenu'
import { Modal } from 'antd'

export default function SettingsModal({ close, settingsSection }) {
  const { data: profile } = useProfile()

  const [section, setSection] = useState(settingsSection)
  const Section = find(menuSettings, { key: section }).element

  return (
    <Modal
      styles={{ content: { padding: 0, background: 'transparent' } }}
      transitionName="zoom"
      closeIcon={false}
      footer={null}
      centered
      open={true}
      onCancel={() => close(false)}
      width={1000}
    >
      <div className="flex w-full overflow-hidden rounded-xl">
        {!!profile && (
          <>
            <SettingsMenu section={section} setSection={setSection} />
            <div className="scroll relative grow bg-container">
              <div className="absolute left-0 top-0 h-full w-full overflow-auto scroll">{Section}</div>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}
