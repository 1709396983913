import { useTranslation } from 'react-i18next'
import { Select as SelectAnt, Tag } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { omit } from 'lodash'

export default function Select(props) {
  const { t } = useTranslation()

  const options = props.options.map((option) => ({ ...option, label: t(option.label) }))

  return (
    <SelectAnt
      showSearch
      placeholder={t('form.empty-field')}
      optionFilterProp="children"
      optionRender={(option) =>
        props.tags ? (
          <Tag icon={<FontAwesomeIcon className="mr-1.5" icon={option.data.icon} />} color={option.data.color}>
            {t(option.label)}
          </Tag>
        ) : (
          t(option.label)
        )
      }
      notFoundContent={t('form.no-results')}
      filterOption={(input, option) =>
        (option?.label ?? '')
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .includes(
            input
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
          )
      }
      {...omit(props, ['tags'])}
      options={options}
    />
  )
}
