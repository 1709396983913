import { forwardRef, useContext, useRef, useState } from 'react'
import Input from 'src/@components/form/Input'
import { PageContext } from 'src/@components/page/Page'

const CellTextEditor = forwardRef(({ value: oldValue, data, colDef: { field }, stopEditing, api, eventKey }, ref_) => {
  const [value, setValue] = useState(/^[a-zA-Z]$/.test(eventKey) ? eventKey : oldValue)
  const inputRef = useRef(null)
  const { update } = useContext(PageContext)

  const save = () => {
    if (oldValue !== value) {
      api.applyTransactionAsync({
        update: [{ ...data, [field]: value }],
      })
      
      update({ id: data.id, [field]: value })
    }

    stopEditing()
  }

  return (
    <div className="h-full w-full">
      <Input
        ref={inputRef}
        value={value}
        onBlur={save}
        onPressEnter={() => inputRef.current.blur()}
        onChange={(e) => setValue(e.target.value)}
        style={{ width: '100%', height: '100%' }}
        autoFocus
        variant="filled"
      />
    </div>
  )
})

export default CellTextEditor
