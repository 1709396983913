import { useRef, useState } from 'react'

import { useUI } from 'src/layout/UI'
import { useProfiles, useTranscriber, useUpdateProfile } from 'src/profiles/ProfilesApi'

import Avatar from 'src/@components/Avatar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Button, Tooltip, Typography, Form } from 'antd'

import { supabase } from 'src/supabase'
import { useAuth } from 'src/auth/Auth'
const { Text } = Typography

const { APP_TEAMS_URL } = import.meta.env

export default function SettingsAvatar({ profile: _profile, _update, _remove, formProfile }) {
  const { mutate: update } = useUpdateProfile()
  const { messageApi } = useUI()
  const { rocketchatSession } = useAuth()
  const { data: transcriber } = useTranscriber()

  const [isHovered, setIsHovered] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const fileInputRef = useRef(null)

  const last_name = formProfile && Form.useWatch('last_name', formProfile)
  const avatar = formProfile && Form.useWatch('avatar', formProfile)

  const profile = formProfile ? { last_name, avatar } : _profile

  const removeAvatar = async () => {
    if (_remove) {
      _remove()
    } else {
      update({ id: profile.id, avatar: null })
    }
    setIsHovered(false)
  }

  const handleFileChange = async (event) => {
    const file = event.target.files[0]
    const fileExtension = file.name.split('.').pop()
    const fileName = `${new Date().getTime()}.${fileExtension}`

    setIsLoading(true)

    const { error } = await supabase.storage.from('avatars').upload(fileName, file, {
      cacheControl: '3600',
      upsert: true,
    })

    if (error) {
      messageApi.open({
        type: 'error',
        content: error.message,
        duration: 4,
      })
    } else {
      const {
        data: { publicUrl },
      } = await supabase.storage.from('avatars').getPublicUrl(fileName, 60)
      if (_update) {
        _update(publicUrl)
      } else {
        update({ id: profile.id, avatar: publicUrl })

        if (rocketchatSession) {
          fetch(APP_TEAMS_URL + '/api/v1/users.setAvatar', {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'X-Auth-Token': rocketchatSession.token,
              'X-User-Id': transcriber.rocketchat_id,
            },
            body: JSON.stringify({
              avatarUrl: publicUrl,
            }),
          }).then()
        }
      }
    }

    setIsHovered(false)
    setIsLoading(false)
  }

  return (
    <div
      className="relative inline-flex w-16"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <input ref={fileInputRef} id="fileInput" type="file" style={{ display: 'none' }} onChange={handleFileChange} />
      <Avatar profile={profile} size={64} />
      {isHovered && !isLoading && (
        <div
          onClick={() => fileInputRef.current.click()}
          className="absolute left-0 top-0 inline-flex h-full w-full cursor-pointer flex-col items-center justify-center gap-1.5 rounded-full bg-layout opacity-80"
        >
          <Text>
            <FontAwesomeIcon icon={solid('upload')} size="lg" />
          </Text>
          {!!profile.avatar && (
            <Tooltip title={'Remove'} placement="right">
              <Button
                onClick={(e) => [e.stopPropagation(), removeAvatar()]}
                size="small"
                icon={<FontAwesomeIcon icon={solid('trash')} />}
              ></Button>
            </Tooltip>
          )}
        </div>
      )}
      {isLoading && (
        <div className="absolute left-0 top-0 inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-layout">
          <FontAwesomeIcon className="fa-spinner text-primary" icon={solid('spinner')} size="xl" />
        </div>
      )}
    </div>
  )
}
