import { useTranslation } from 'react-i18next'
import { Input as InputAnt } from 'antd'
import { forwardRef } from 'react'

const Input = forwardRef((props, ref_) => {
  const { t } = useTranslation()

  return <InputAnt placeholder={t('form.empty-field')} {...props} ref={ref_} />
})

export default Input
