import { createContext, useContext, useEffect, useState } from 'react'
import * as Sentry from "@sentry/react";

import SettingsPassword from 'src/settings/SettingsPassword'
import SettingsModal from 'src/@components/settings/SettingsModal'

import { supabase } from 'src/supabase'

const AuthContext = createContext(null)

const { APP_PUSH_KEY } = import.meta.env

export const useAuth = () => {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [session, setSession] = useState(null)
  const [isPasswordOpen, setPasswordOpen] = useState(false)
  const [settingsSection, setSettingsSection] = useState('my-account')
  const [isSettingsOpen, setSettingsOpen] = useState(false)

  const [rocketchatSession, setRocketchatSession] = useState(null)



  useEffect(() => {
    const getSession = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession()
      if (!session) {
        setLoading(false)
      }
    }

    getSession()

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      if (session) {
        Sentry.setUser({ email: session.user.email, id: session.user.id });
        if ((['PASSWORD_RECOVERY'].includes(_event))) {
          toggleSettings()
          setTimeout(() => {
            setPasswordOpen(true)
          }, 500)
        }

        if (_event === 'INITIAL_SESSION' && session.user.app_metadata.universus) {
          subscribeNotification(session.user.id)
        }
      }

      setSession(session)

      supabase.from('profiles').update({
        last_sign_in_at: new Date().toISOString(),
      }).eq('user_id', session?.user.id).then()

      setLoading(false)
    })

    return () => subscription.unsubscribe()
  }, [])

  const signOut = () => supabase.auth.signOut()
  const openPassword = () => setPasswordOpen(true)
  const toggleSettings = (section) => [setSettingsSection(section || settingsSection), setSettingsOpen(!isSettingsOpen)]


  return (
    <AuthContext.Provider value={{ rocketchatSession, setRocketchatSession, session, signOut, openPassword, toggleSettings, isSettingsOpen }}>
      {isPasswordOpen && <SettingsPassword close={() => setPasswordOpen(false)} />}
      {isSettingsOpen && session && (
        <SettingsModal settingsSection={settingsSection} open={true} close={toggleSettings} />
      )}

      {!loading && children}
      {loading && <div className="h-screen w-screen bg-base"></div>}
    </AuthContext.Provider>
  )
}

export function subscribeNotification(user_id) {
  if (navigator.serviceWorker && 'PushManager' in window) {
    navigator.serviceWorker.ready.then(function (registration) {
      const vapidPublicKey = APP_PUSH_KEY;
      const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);
  
      registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: convertedVapidKey
      })
        .then(function (subscription) {
          supabase.from('profiles').update({
            push_subscription: subscription
          }).eq('user_id', user_id).then()
        })
        .catch(function (err) {
          console.log('Failed to subscribe the user: ', err);
        });
    });
  }
}

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}