export default function (folders, { doctors, clinics, duration, specialities, review, languages, jobs, enable, emergency }) {
    //Logic duplicated from cells/publish-supabase
    return folders.filter(folder => {
        if (!enable) {
            return false
        }

        if (clinics.length) {
            if (!clinics.includes(folder.company.id)) {
                return false
            }
        }

        if (doctors.length) {
            if (!doctors.includes(folder.doctor.id)) {
                return false
            }
        }

        if (duration) {
            if (folder.duration > (duration * 60)) {
                return false
            }
        }

        if (!emergency && folder.emergency) {
            return false
        }

        if (folder.work.transcriber && !jobs.includes('correction')) {
            return false
        }

        if (folder.work.service === "transcribe" && !folder.work.transcriber && !jobs.includes('transcribe')) {
            return false
        }

        if (folder.work.service === "translate" && !jobs.includes('translate')) {
            return false
        }

        return true
    })
}