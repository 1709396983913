import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown, Button, Badge, Typography, Tooltip } from 'antd'
import clsx from 'clsx'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from 'src/auth/Auth'
import { useUI } from 'src/layout/UI'
import { useTranscriber } from 'src/profiles/ProfilesApi'
import { supabase } from 'src/supabase'

const { APP_API_URL, APP_TEAMS_URL } = import.meta.env

export default function TeamsNavigation() {
    const location = useLocation()
    const navigate = useNavigate()
    const isOpen = location.pathname.includes('teams')
    const { data: transcriber } = useTranscriber()

    const { rocketchatSession, setRocketchatSession, session } = useAuth()

    useEffect(() => {
        const fetchRocketchat = async () => {
            const { data, error } = await fetch(`${APP_API_URL}/rocketchat`, {
                headers: {
                    Authorization: `Bearer ${session.access_token}`,
                }
            }).then(res => res.json())

            setRocketchatSession(data)
        }

        fetchRocketchat()
    }, [])

    const rocketchatStatus = {
        'online': {
            icon: solid('circle'),
            color: '#27ae60',
            text: 'En ligne'
        },
        'busy': {
            icon: solid('circle-minus'),
            color: '#c0392b',
            text: 'Occupé'
        },
        'away': {
            icon: solid('clock'),
            color: '#f39c12',
            text: 'Absent'
        },
        'offline': {
            icon: light('circle'),
            color: '#2c3e50',
            text: 'Déconnecté'
        },
    }

    return <div className="flex items-center">
        {rocketchatSession?.status && <Tooltip placement='left' title={rocketchatStatus[rocketchatSession?.status]?.text}>
            <Dropdown
                menu={{
                    onClick: ({ key }) => {
                        fetch(APP_TEAMS_URL + '/api/v1/users.setStatus', {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                'X-Auth-Token': rocketchatSession.token,
                                'X-User-Id': transcriber.rocketchat_id,
                            },
                            body: JSON.stringify({
                                status: key,
                            }),
                        }).then()

                        setRocketchatSession({
                            ...rocketchatSession,
                            status: key
                        })
                    },
                    items: Object.keys(rocketchatStatus).filter(key => key !== rocketchatSession?.status).map(key => ({
                        key,
                        label: (
                            <Typography.Text className='flex items-center gap-2'>
                                <FontAwesomeIcon icon={rocketchatStatus[key].icon} style={{ color: rocketchatStatus[key].color }} />
                                {rocketchatStatus[key].text}
                            </Typography.Text>
                        ),
                    }))
                }}
                placement="topRight"
                trigger={['click']}
            >
                {<Button shape="circle" type="text" icon={<FontAwesomeIcon size="sm" icon={rocketchatStatus[rocketchatSession?.status]?.icon} />} style={{ color: rocketchatStatus[rocketchatSession?.status]?.color }} />}
            </Dropdown>
        </Tooltip>}
        <Button style={{ borderRadius: 36 }} onClick={() => navigate('/teams')} type={isOpen ? 'primary' : 'text'} icon={<FontAwesomeIcon icon={isOpen ? solid('messages') : light('messages')} />} size={"large"}>
            <div className={clsx('inline-flex items-center', rocketchatSession?.unread && 'gap-6')}>
                Équipe
                {!!rocketchatSession?.unread && <Badge offset={[-6]} style={{ color: 'white' }} count={rocketchatSession?.unread}><span></span> </Badge>}
            </div>
        </Button>
    </div>
}