import { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Tooltip, Typography } from 'antd'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

const { Text } = Typography

export default function MenuButton({ label, icon, iconActive, isActive: _isActive, onClick }) {
  const { t } = useTranslation()
  const [isHovered, setIsHovered] = useState(false)

  const isActive = isHovered || _isActive

  return (
    // <Tooltip title={t('menus.' + label)} placement="bottom" arrow={false}>
      <div
        onClick={onClick}
        className={clsx("flex h-9 w-9 cursor-pointer items-center justify-center rounded-full", isActive && "bg-fill-secondary")}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Text type={isActive ? 'default' : 'secondary'}>
          <FontAwesomeIcon icon={isActive ? iconActive : icon} size="lg" />
        </Text>
      </div>
    // </Tooltip>
  )
}
