import { useState } from 'react'

import { useUI } from 'src/layout/UI'
import { useProfiles, useUpdateProfile } from 'src/profiles/ProfilesApi'

import { Button, Form, Modal, Input } from 'antd'
import Label from 'src/@components/Label'
import { useTranslation } from 'react-i18next'

import { supabase } from 'src/supabase'

export default function SettingsEmail({ profile, close }) {
  const { t } = useTranslation()
  const { mutate: update } = useUpdateProfile()
  const { messageApi } = useUI()

  const [isLoading, setLoading] = useState(false)

  const onFinish = async (values) => {
    setLoading(true)

    update({ id: profile.id, ...values }, {
      onSuccess: async () => {
        const { error } = await supabase.auth.updateUser(values)

        setLoading(false)
        if (error) {
          let message = error.message
  
          if (message.includes('duplicate')) {
            message = t('email-exists')
          }
  
          messageApi.open({
            type: 'error',
            content: message,
            duration: 4,
          })
        } else {
          messageApi.open({
            type: 'success',
            content: 'Email change requested. Check your inbox for a validation email.',
            duration: 4,
          })
  
          close()
        }

      },
      onError: () => {
        setLoading(false)
      }
    })
  }

  return (
    <Modal
      styles={{ content: { padding: 0, background: 'transparent' } }}
      transitionName="zoom"
      closeIcon={false}
      footer={null}
      centered
      open={true}
      onCancel={close}
      width={300}
    >
      <div className="flex w-full overflow-hidden rounded-xl bg-layout p-6 gap-6">
        <Form
          disabled={isLoading}
          validateTrigger={'onBlur'}
          onFinish={onFinish}
          className="flex w-full flex-col gap-6"
        >
          <Label label={t("settings.my-account.new-email")}>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: t("rules.email-required") },
                {
                  pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: t("rules.email-valid"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Label>
          <Button loading={isLoading} htmlType="submit" type="primary">
            {t("settings.my-account.send-link")}
          </Button>
        </Form>
      </div>
    </Modal>
  )
}
