import { Button, Typography } from 'antd'
import FormMenu from 'src/@components/form/FormMenu'
import { useTranslation } from 'react-i18next'
import { useProfile } from 'src/profiles/ProfilesApi'

import store from 'src/assets/app-store.png'
import storeDark from 'src/assets/app-store-dark.png'
import play from 'src/assets/google-play.png'
import playDark from 'src/assets/google-play-dark.png'
import { useUI } from 'src/layout/UI'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useSettings } from 'src/settings/SettingsApi'

export default function FormActions({
  isMutationLoading,
  onClose,
  isUpdate,
  outlined,
  initialValues,
}) {
  const { t } = useTranslation()
  const { data: settings } = useSettings()
  const { data: profile } = useProfile()
  const { darkMode } = useUI()
  const [hideApp, setHideApp] = useState(localStorage.getItem('hideApp'))

  const hideAppFn = () => {
    setHideApp(true)
    localStorage.setItem('hideApp', true)
  }

  return (
    // <div className="relative box-border flex flex w-full justify-end gap-1.5 border-0 border-t border-solid border-layout px-4 py-2">
    <div className="relative box-border flex flex w-full justify-between gap-1.5 border-0 border-layout py-2 items-center">
      {/* {!hideApp && <div className='inline-flex flex-col gap-2'>
        <Typography.Text className="text-label" style={{ opacity: 0.6 }}>
          <FontAwesomeIcon style={{ marginRight: 6 }} icon={light('info-circle')} />
          Enregistrer vos dictée a partir de l'application
        </Typography.Text>
        <div className='flex items-center gap-2'>
          <img style={{ height: 34, opacity: 0.7, cursor: 'pointer' }} src={darkMode ? storeDark : store} alt="App Store" />
          <img style={{ height: 34, opacity: 0.7, cursor: 'pointer' }} src={darkMode ? playDark : play} alt="Google Play" />
          <Button size="small" onClick={hideAppFn}>Ne plus afficher</Button>
        </div>
      </div>} */}
      {<div></div>}
      <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
        {settings.permissions && settings.permissions['delete-folder'] && isUpdate && profile.universus && (
          <FormMenu
            values={initialValues}
            closeForm={onClose}
          />
        )}

        {settings.permissions && settings.permissions['update-folder'] && <Button loading={isMutationLoading} htmlType="submit" type={outlined ? "default" : "primary"}>
          {t(`form.${isUpdate ? 'update' : 'create'}`)}
        </Button>}
      </div>
    </div>
  )
}
