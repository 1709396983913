import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'

export default function ConfirmSaveModal({ open, onOk, onCancel }) {
  const { t } = useTranslation()

  return (
    <Modal zIndex={100000} closeIcon={false} title={t('form.confirm.title')} open={open} onOk={onOk} cancelText={t('cancel')} onCancel={onCancel} okText={t('form.confirm.discard')}>
      <p style={{ whiteSpace: 'pre-line' }}>{t('form.confirm.text')}</p>
    </Modal>
  )
}