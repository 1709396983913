import { useCallback, useEffect, useRef, useState } from 'react'
import { debounce } from 'lodash'

import { subscribeNotification, useAuth } from 'src/auth/Auth'
import { useProfile, useProfiles, useTranscriber, useUpdateProfile } from 'src/profiles/ProfilesApi'
import { useSettings, useUpdateSettings } from 'src/settings/SettingsApi'

import SettingsEmail from 'src/settings/SettingsEmail'
import SettingsAvatar from 'src/settings/SettingsAvatar'

import Label from 'src/@components/Label'
import { light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Input, Form, Button, Switch } from 'antd'
import { useTranslation } from 'react-i18next'
import SettingsItem from 'src/@components/settings/SettingsItem'


import { Select } from 'antd'
import { useUI } from 'src/layout/UI'
import { supabase } from 'src/supabase'

const { APP_TEAMS_URL } = import.meta.env

export default function SettingsAccount() {
  const { signOut, openPassword, toggleSettings, rocketchatSession } = useAuth()
  const { mutate: update } = useUpdateProfile()
  const { data: profile } = useProfile()
  const { darkMode, promptInstall } = useUI()
  const { t } = useTranslation()
  const { data: settings } = useSettings()
  const { mutate: updateSettings } = useUpdateSettings()
  const { data: transcriber } = useTranscriber()

  const [isEmailOpen, setEmailOpen] = useState(false)
  const formRef = useRef(null)

  const throttledUpdate = useCallback(
    debounce(async (values) => {
      update({ id: profile.id, ...values })
    }, 400),
    []
  )

  const onValuesChange = (changedValues) => {
    const key = Object.keys(changedValues)[0]
    const value = Object.values(changedValues)[0]

    setTimeout(() => {
      if (!formRef.current.getFieldError(key).length) {
        throttledUpdate({ [key]: value })
      }
    }, 100)
  }

  return (
    <div className="flex flex-col gap-4 p-6">
      {isEmailOpen && <SettingsEmail profile={profile} close={() => setEmailOpen(false)} />}


      <Form ref={formRef} className="flex flex-col gap-6" initialValues={profile} onValuesChange={onValuesChange}>
        <div className='flex gap-3'>
          <div style={{ marginRight: 10 }}>
            <SettingsAvatar profile={profile} />
          </div>
          <Label label={t('profiles.fields.last_name')}>
            <Form.Item name="last_name">
              <Input />
            </Form.Item>
          </Label>
          <Label label={t('profiles.fields.first_name')}>
            <Form.Item name="first_name">
              <Input />
            </Form.Item>
          </Label>
        </div>
        {/* <Label label={t('profiles.fields.phone')}>
          <Form.Item name="phone">
            <Input />
          </Form.Item>
        </Label> */}
        {/* @TRANSLATE */}
        <SettingsItem
          icon={light('envelope')}
          label={t('settings.my-account.change-email')}
          actions={<Button onClick={() => setEmailOpen(true)}>Change</Button>}
        />
        <SettingsItem
          icon={light('key')}
          label={t('settings.my-account.change-password')}
          actions={
            <Button onClick={openPassword}>
              Change
            </Button>
          }
        />

        <SettingsItem
          icon={light('circle-half-stroke')}
          label={t('settings.my-settings.theme')}
          description={t('settings.my-settings.theme-description')}
          actions={
            <Select
              popupMatchSelectWidth={false}
              onChange={(value) => {
                updateSettings({ theme: value })

                if (rocketchatSession) {
                  fetch(APP_TEAMS_URL + '/api/v1/users.setPreferences', {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      'X-Auth-Token': rocketchatSession.token,
                      'X-User-Id': transcriber.rocketchat_id,
                    },
                    body: JSON.stringify({
                      data: {
                        themeAppearence: value === 'system' ? 'auto' : value
                      },
                    }),
                  }).then()
                }
              }}
              value={settings.theme}
              options={[
                { value: 'system', label: `${t('settings.my-settings.theme-system')} (${t(`settings.my-settings.theme-${darkMode ? 'dark' : 'light'}`)})` },
                { value: 'dark', label: t('settings.my-settings.theme-dark') },
                { value: 'light', label: t('settings.my-settings.theme-light') },
              ]}
            />
          }
        />
        <SettingsItem
          icon={light('globe')}
          label={t('settings.language.language')}
          description={t('settings.language.language-description')}
          actions={
            <Select
              popupMatchSelectWidth={false}
              onChange={(value) => updateSettings({ lang: value })}
              value={settings.lang}
              options={[
                { label: t('langs.en'), value: 'en' },
                { label: t('langs.fr'), value: 'fr' }
              ]}
            />
          }
        />

        {profile.role === 'administrator' && <SettingsItem
          icon={light('bell')}
          label={t('settings.my-account.notification-receipt')}
          description={''}
          actions={
            <Switch onChange={(v) => updateSettings({ notification_receipt: v })} value={settings.notification_receipt} />
          }
        />}

        {profile.role !== 'transcriber' && <SettingsItem
          icon={light('bell')}
          label={t('settings.my-account.notification-report')}
          description={''}
          actions={
            <Switch onChange={(v) => updateSettings({ notification_report: v })} value={settings.notification_report} />
          }
        />}

        {profile.role === 'transcriber' && <SettingsItem
          icon={light('bell')}
          label={"Notifications"}
          description={"Recevoir une notification lorsqu'un dossier est disponible ou mis à jour par un client"}
          actions={
            <Switch onChange={(v) => {
              updateSettings({ notification_transcriber: v })
              if (v) {
                subscribeNotification(profile.user_id)
              }
            }} value={settings.notification_transcriber} />
          }
        />}

        <div className="flex flex-col gap-4"></div>
        <div className="flex justify-between">
          {promptInstall && <Button onClick={() => promptInstall.prompt()} icon={<FontAwesomeIcon icon={solid('download')} />}>Installer l'application</Button>}

          <Button className="w-40 ml-auto" onClick={() => [toggleSettings(false), signOut()]} danger>
            {t('settings.my-account.sign-out')}
          </Button>
        </div>
      </Form>
    </div>
  )
}