import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, DatePicker, Button, Modal, Tabs, Typography, Table, Tooltip } from "antd";
import { useEffect, useMemo, useState } from "react";
import { supabase } from "src/supabase";
import { DateTime } from 'luxon'
import { getDoctorLabel } from "src/folders/FoldersForm";
import { useAuth } from "src/auth/Auth";

const { APP_API_URL } = import.meta.env

export default function CompanyInvoices({ company_id, close }) {
    const { session } = useAuth()
    const [loadingSend, setLoadingSend] = useState(false)
    const [loading, setLoading] = useState(true)
    const [tab, setTab] = useState('waiting')
    const [company, setCompany] = useState(true)
    const [folders, setFolders] = useState(true)
    const [invoices, setInvoices] = useState(true)
    const [filterDate, setFilterDate] = useState(null)

    const fetchInvoices = async () => {
        setLoading(true)
        const query = supabase.from('invoices').select('*, folders(*, pydio_id, invoice_unit, reference, doctor:doctors(profile:profiles(*)))').eq('company_id', company_id)

        if (filterDate) {
            const { firstDay, lastDay } = getFirstAndLastDayOfMonth(filterDate['$d']);
            query.gt('created_at', firstDay).lt('created_at', lastDay)
        }

        const { data } = await query.order('created_at', { ascending: false })

        setInvoices(data)

        setLoading(false)
    }

    useEffect(() => { if (filterDate) fetchInvoices() }, [filterDate])

    useEffect(() => {
        if (company_id) {
            setLoading(true)
            const fetch = async () => {
                const { data } = await supabase.from('companies').select('*, settings:company_settings(*)').eq('id', company_id).single()
                const { data: folders } = await supabase.from('folders').select('*, pydio_id, invoice_unit, reference, doctor:doctors(profile:profiles(*))').eq('company_id', company_id).is('invoice_id', null)

                setCompany(data)
                setFolders(folders)

                await fetchInvoices()
            }

            fetch()
        }
    }, [company_id])

    const payed = async (invoiceId) => {
        await supabase.from('invoices').update({ payed: true, payed_at: new Date() }).eq('id', invoiceId)
        setInvoices(invoices.map(invoice => invoice.id === invoiceId ? {
            ...invoice,
            payed: true,
            payed_at: new Date().toISOString()
        } : invoice))
    }

    const send = async () => {
        setLoadingSend(true)
        const { data, error } = await fetch(`${APP_API_URL}/send-invoice/${company.id}`, {
            headers: {
                Authorization: `Bearer ${session.access_token}`,
            },
        }).then(res => res.json())
        setLoadingSend(false)
        if (error) {
            messageApi.open({
                type: 'error',
                content: error.message,
                duration: 4,
            })
        } else {
            setFolders([])
            setInvoices([{
                folders,
                total: folders.reduce((a, b) => a + b.invoice_unit * company.settings.invoice_unit_price, 0),
                created_at: new Date().toISOString(),
                payed: false,
            }, ...invoices])
            setTab('sent')
            messageApi.open({
                type: 'success',
                content: "Facture envoyé",
                duration: 4,
            })
        }
    }

    return <Modal
        width={600}
        closeIcon={false}
        footer={null}
        centered
        open={!!company_id}
        onCancel={close}
        styles={{ content: { padding: 0 } }}
    >
        <div style={{ height: 600 }} className="flex flex-col">
            <Tabs className="tabs-transcription" activeKey={tab} items={[
                {
                    key: 'waiting',
                    label: <Typography.Text className="flex items-center gap-4" style={{ margin: 0 }}>
                        <FontAwesomeIcon icon={solid('folders')} />
                        Dossiers en attente
                    </Typography.Text>
                }, {
                    key: 'sent',
                    label: <Typography.Text className="flex items-center gap-4" style={{ margin: 0 }}>
                        <FontAwesomeIcon icon={solid('paper-plane')} />
                        Factures envoyées
                    </Typography.Text>
                }]} onChange={setTab} />
            {!loading && <>
                {tab === "waiting" && <div className="flex flex-col flex-grow">
                    <div className="flex-grow relative">
                        <div className="absolute top-0 left-0 w-full h-full">
                            <div className="relative h-full scroll overflow-auto">
                                <Folders company={company} folders={folders} waiting />
                            </div>
                        </div>
                    </div>
                    {!!folders.length && <div className="p-4">
                        <Tooltip title={company.settings.invoice_email}>
                            <Button block onClick={send} loading={loadingSend} icon={<FontAwesomeIcon icon={solid('paper-plane')} />} type="primary">Envoyer la facture ({folders.reduce((a, b) => a + b.invoice_unit * company.settings.invoice_unit_price, 0)}$)</Button>
                        </Tooltip>
                    </div>}
                </div>}

                {tab === "sent" && <div className="flex flex-col gap-4 p-4 flex-grow">
                    <DatePicker picker="month" allowClear value={filterDate} onChange={(v) => setFilterDate(v)} variant={"filled"} format="MM/YYYY" placeholder={"Envoyé le"} />
                    <div className="flex-grow relative">
                        <div className="collapse-no-padding absolute top-0 left-0 w-full h-full pr-2 scroll overflow-auto flex flex-col gap-2">
                            {invoices.map((invoice, key) => {
                                const created_at = invoice.created_at && DateTime.fromISO(invoice.created_at)
                                const payed_at = invoice.payed_at && DateTime.fromISO(invoice.payed_at)

                                return <Collapse
                                    key={invoice.id}
                                    items={[
                                        {
                                            key,
                                            label: <div className="flex flex-col">
                                                <Typography.Text strong>{invoice.folders.length} Dossiers</Typography.Text>
                                                <Typography.Text>Envoyé le {created_at.toLocaleString(DateTime.DATE_FULL)}</Typography.Text>
                                            </div>,
                                            children: <div>
                                                <Folders company={company} folders={invoice.folders} />
                                            </div>,
                                            extra: <div className="flex items-center gap-2">
                                                <div className="flex flex-col items-end">
                                                    <Typography.Text strong style={{ fontSize: 14 }}>{invoice.total} $</Typography.Text>
                                                    <div className="flex items-center gap-2">
                                                        {invoice.payed && <>
                                                            <FontAwesomeIcon style={{ color: '#27ae60' }} icon={solid('check-circle')} />
                                                            <Typography.Text>Payée le {payed_at.toLocaleString(DateTime.DATE_FULL)}</Typography.Text>
                                                        </>}

                                                        {!invoice.payed && <Button onClick={(e) => [e.stopPropagation(), e.preventDefault(), payed(invoice.id)]} icon={<FontAwesomeIcon icon={solid('check-circle')} />}>Payée</Button>}
                                                    </div>
                                                </div>
                                                <Button shape="circle" icon={<FontAwesomeIcon icon={solid('file-arrow-down')} />} />
                                            </div>,
                                        }
                                    ]}
                                />
                            })}
                        </div>
                    </div>
                </div>}
            </>}

            {(loading) && <div className="flex flex-col flex-grow gap-4 z-20 items-center justify-center w-full">
                <Typography.Text type="secondary" className="relative">
                    <FontAwesomeIcon icon={solid('file-invoice-dollar')} size={"5x"} />
                    <FontAwesomeIcon
                        style={{ right: -24, bottom: 0 }}
                        className="fa-spinner text-primary absolute opacity-60"
                        icon={solid('spinner')}
                        size="lg"
                    />
                </Typography.Text>
                <Typography.Text type="secondary">
                    <strong>Chargement de la facturation...</strong>
                </Typography.Text>
            </div>}
        </div>
    </Modal>
}

function getFirstAndLastDayOfMonth(date) {
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString();
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString();
    return { firstDay, lastDay };
}

function Folders({ company, folders, waiting = false }) {
    const columns = [
        {
            title: 'Tutum',
            dataIndex: 'pydio_id',
            key: 'pydio_id',
            render: (value) => value,
        },
        {
            title: 'Reference',
            dataIndex: 'reference',
            key: 'reference',
            render: (value) => value,
        },
        {
            title: 'Médecin',
            dataIndex: 'doctor',
            key: 'doctor',
            render: (value) => getDoctorLabel(value.profile),
        },
        {
            title: 'Unités',
            dataIndex: 'invoice_unit',
            key: 'invoice_unit',
            render: (value) => value,
        },
        {
            title: 'Total',
            dataIndex: 'invoice_unit',
            key: 'invoice_unit',
            render: (value) => value * company.settings.invoice_unit_price + ' $',
        },
    ]

    const dataSource = useMemo(() => folders.map(f => ({ ...f, key: f.id })), [folders])

    return <Table columns={columns} sticky bordered={false} pagination={{ position: ['none', 'bottomCenter'] }} dataSource={dataSource} />
}