import { useEffect, useRef, useState } from 'react'
import { Navigate, useLocation, Link } from 'react-router-dom'

import { useAuth } from 'src/auth/Auth'
import { supabase } from 'src/supabase'

import { Button, Input, Typography, Form, message } from 'antd'
import Universus from 'src/assets/universus.png'
import Label from 'src/@components/Label'
import { useTranslation } from 'react-i18next'

const { Title, Text } = Typography

export default function SignIn() {
  const { t } = useTranslation()
  const { session } = useAuth()
  const location = useLocation()
  const [messageApi, contextHolder] = message.useMessage()
  const [isLoading, setLoading] = useState(false)
  const refPassword = useRef(null)
  const [logo, setLogo] = useState(localStorage.getItem('logo'))

  useEffect(() => {
    if (location.state?.forgotPassword) {
      messageApi.open({
        type: 'success',
        content: t('auth.forgot-password-state'),
        duration: 10
      })
    }

    const hashParams = new URLSearchParams(location.hash.substring(1))
    const errorDescription = hashParams.get('error_description')

    if (errorDescription) {
      messageApi.open({
        type: 'error',
        content: errorDescription,
        duration: 10,
      })
    }
  }, [])

  if (session) {
    return <Navigate to={location.state?.pathname || '/'} />
  }

  async function onFinish(values) {
    setLoading(true)

    messageApi.open({
      type: 'loading',
      content: t('auth.signing-in'),
      duration: 0,
    })

    const { error } = await supabase.auth.signInWithPassword(values)

    setLoading(false)

    messageApi.destroy()

    if (error) {
      messageApi.open({
        type: 'error',
        content: error.message,
        duration: 0,
      })
    }
  }

  function isValidURL(string) {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  }

  return (
    <div className="box-border min-h-screen w-full bg-base py-16">
      <div className='w-[400px] mx-auto flex flex-col'>
        <Link to="/sign-in" className="flex items-center justify-between">
          <div className="flex col cursor-pointer items-center gap-4">
            <img src={Universus} className='w-14 h-14' />
            <Text strong className="text-lg" style={{
              fontFamily: '"Raleway", sans-serif',
              fontOpticalSizing: "auto",
              fontWeight: 600,
              fontStyle: "normal"
            }}>
              UNIVERSUS
            </Text>
          </div>
          <div>
            {logo && <>
              {isValidURL(logo) && <img style={{ height: 42 }} src={logo} />}
              {!isValidURL(logo) && <Text>{logo}</Text>}
            </>}
          </div>
        </Link>

        {contextHolder}
        <Title level={2} style={{ marginBottom: 0 }}>{t('auth.welcome-back')}</Title>
        <Text type="secondary">{t('auth.sign-in-text')}</Text>

        <Form validateTrigger={'onBlur'} disabled={isLoading} onFinish={onFinish} className="flex flex-col gap-4 mt-6">
          <Label label={t('auth.email')}>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: t('rules.email-required') },
                {
                  pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: t('rules.email-valid'),
                },
              ]}
            >
              <Input placeholder="you@example.com" />
            </Form.Item>
          </Label>

          <Label label={t('auth.password')} actionsEnd={<Link tabIndex="-1" to="/forgot-password">{t('auth.forgot-password-link')}</Link>}>
            <Form.Item name="password" rules={[{ required: true, message: t('rules.password-required') }]}>
              <Input.Password ref={refPassword} placeholder="••••••••" />
            </Form.Item>
          </Label>

          <Button loading={isLoading} htmlType="submit" block type="primary">
            {t('auth.sign-in')}
          </Button>
        </Form>
      </div>
    </div>
  )
}
