import { duotone, light, regular, solid, thin } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Popover, Slider, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import ReactHowler from 'react-howler'

export default function Player({ folder, dictation }) {
    const [seek, setSeek] = useState(0)
    const [position, setPosition] = useState(0)
    const [loading, setLoading] = useState(true)

    const player = useRef(null);
    const playingRef = useRef(false);

    const [playing, setPlaying] = useState(false);
    const [volume, setVolume] = useState(1);
    const [cachedVolume, setCachedVolume] = useState(1);
    const [rate, setRate] = useState(1);

    useEffect(() => { playingRef.current = playing }, [playing])

    const togglePlay = () => {
        const _playing = !playing
        setPlaying(_playing)
        seekUpdate(_playing)
    }

    function seekUpdate(_playing = playingRef.current) {
        try {
            if (_playing) {
                const _seek = player.current.seek()
    
                setSeek(_seek)
                setPosition((_seek * 100) / folder.duration)
                setTimeout(seekUpdate, 1000)
            }
            
        } catch (error) {}
    }

    const handleVolumeChange = (value) => {
        setVolume(value / 100);
    };

    const handleSpeedChange = (value) => {
        const _rate = value / 100
        setRate(_rate);
        player.current.rate(_rate)
    };

    const skipTime = (seconds) => {
        const _seek = player.current.seek() + seconds
        player.current.seek(_seek)
        setPosition((_seek * 100) / folder.duration)
    };

    const handleSeekChange = (value) => {
        const newPosition = (value * folder.duration) / 100;
        setPosition(value)
        setSeek(newPosition);
        player.current.seek(newPosition)
    };

    const secondsToMinutes = (seconds) => {
        const minutes = Math.floor(parseInt(seconds) / 60);
        const remainingSeconds = parseInt(seconds) % 60;
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    }

    return <div className={clsx("flex flex-col grow ml-2", !folder.dictation_path && "opacity-0")}>
        {dictation && <ReactHowler
            onLoad={() => setLoading(false)}
            src={dictation}
            playing={playing}
            rate={rate}
            volume={volume}
            loop={false}
            ref={player}
        />}

        <Slider disabled={loading} step={0.1} defaultValue={0} value={position} onChange={handleSeekChange} style={{ position: 'relative', top: 7 }} />
        <div className="flex justify-between w-full items-center" style={{ position: 'relative', bottom: 4 }}>
            <div>
                <Typography.Text style={{ userSelect: 'none', fontWeight: '400', fontSize: 11, position: 'relative', left: 8, bottom: 0 }}>{secondsToMinutes(seek)} / {secondsToMinutes(folder.duration)}</Typography.Text>
            </div>
            <div>
                {/* <Tooltip placement="bottom" arrow={false} title={<span style={{ fontWeight: 500, fontSize: 11, display: 'flex', alignItems: 'center', gap: 10 }}>CTRL LEFT</span>}> */}
                <Button onClick={() => skipTime(-5)} shape="circle" size="small" type="text" icon={<FontAwesomeIcon size="sm" icon={solid('backward-step')} />} />
                {/* </Tooltip> */}
                {/* <Tooltip placement="bottom" arrow={false} title={<span style={{ fontWeight: 500, fontSize: 11 }}>CTRL SPACE</span>}> */}
                {!loading && <Button shape="circle" type="text" icon={<FontAwesomeIcon size="xl" icon={playing ? solid('pause') : solid('play')} />} onClick={togglePlay} />}
                {loading && <Button shape="circle" type="text" icon={<FontAwesomeIcon size="sm" className={"fa-spinner"} icon={light('circle-notch')} />} />}
                {/* </Tooltip> */}
                {/* <Tooltip placement="bottom" arrow={false} title={<span style={{ fontWeight: 500, fontSize: 11, display: 'flex', alignItems: 'center', gap: 10 }}>CTRL RIGHT</span>}> */}
                <Button onClick={() => skipTime(5)} shape="circle" size="small" type="text" icon={<FontAwesomeIcon size="sm" icon={solid('forward-step')} />} />
                {/* </Tooltip> */}
            </div>
            <div className="flex items-center">
                <Popover arrow={false} rootClassName='popover-no-padding' content={<div style={{ height: 100, padding: '16px 0px' }}>
                    <Slider max={200} vertical value={rate * 100} onChange={handleSpeedChange} />
                </div>}>
                    <Button onClick={() => setRate(1)} shape="circle" style={{ opacity: 0.7 }} size="small" type="text" icon={<FontAwesomeIcon size="sm" icon={light('gauge-simple')} />} />
                </Popover>

                <Popover arrow={false} rootClassName='popover-no-padding' content={<div style={{ height: 100, padding: '16px 0px' }}>
                    <Slider vertical value={volume * 100} onChange={handleVolumeChange} />
                </div>}>
                    <Button onClick={() => {
                        if (!volume && cachedVolume) {
                            setVolume(cachedVolume)
                        } else if (!!volume) {
                            setCachedVolume(volume)
                            setVolume(0)
                        }
                    }} shape="circle" style={{ opacity: 0.7 }} size="small" type="text" icon={<FontAwesomeIcon size="sm" icon={light('volume')} />} />
                </Popover>
            </div>
        </div>
    </div>
}