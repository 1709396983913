import { useEffect, useMemo, useState } from 'react'

import ConfirmSaveModal from 'src/@components/form/ConfirmSaveModal'
import FormActions from 'src/@components/form/FormActions'
import FormHeader from 'src/@components/form/FormHeader'
import { v4 as uuidv4, validate } from 'uuid'
import { useNavigate } from 'react-router-dom'

import { Drawer, theme, Form as FormAnt, Modal } from 'antd'
import clsx from 'clsx'
import { useSettings } from 'src/settings/SettingsApi'
import { useProfile } from 'src/profiles/ProfilesApi'

const { useToken } = theme

export default function Form({
  isOpen,
  FormComponent,
  closeForm,
  isInline = false,
  initialValues = {},
  syncRequests,
  insert,
  update,
  captureResult,
  remove,
  icons,
  isModal,
  children,
}) {
  const { token } = useToken()

  const [form] = FormAnt.useForm()
  const [isMutationLoading, setIsMutationoading] = useState(false)
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false)
  const [valuesChange, setValuesChange] = useState({})
  const navigate = useNavigate()

  const { data: profile } = useProfile()
  const { data: settings } = useSettings()

  useEffect(() => {
    form.resetFields()
  }, [initialValues])

  const isUpdate = useMemo(() => {
    return !!initialValues.id && !validate(initialValues.id)
  }, [initialValues])
  const id = useMemo(() => initialValues.id || uuidv4(), [initialValues])
  
  const onClose = (e) => {
    if (closeForm) {
      if (form.isFieldsTouched()) {
        setIsModalConfirmOpen(true)
      } else {
        closeForm()
      }
    }
  }

  const onFinish = (values) => {
    if (!syncRequests) {
      if (closeForm) {
        closeForm()
      }
    } else {
      setIsMutationoading(true)
    }

    if (isUpdate) {
      update(
        { id, ...values },
        syncRequests && {
          onSuccess: (result) => {
            if (closeForm) {
              closeForm()
            }

            if (captureResult) {
              captureResult(result)
            }

            setIsMutationoading(false)
          },
          onError: () => {
            setIsMutationoading(false)
          },
        }
      )
    } else {
      insert(
        { id, ...values },
        syncRequests && {
          onSuccess: () => {
            closeForm()
            navigate(profile.universus ? '/available' : '/inprogress')
            setIsMutationoading(false)
          },
          onError: () => {
            setIsMutationoading(false)
          },
        }
      )
    }
  }

  const disabled = isMutationLoading
  const readOnly = (isUpdate && settings && settings.permissions && !settings.permissions['update-folder'])

  const innerForm = (
    <FormAnt
      onValuesChange={(values) => setValuesChange(values)}
      className="flex h-full flex-col"
      disabled={disabled}
      validateTrigger={'onBlur'}
      form={form}
      onFinish={onFinish}
      initialValues={{ id, ...initialValues }}
    >
      {!isInline && <FormHeader icons={icons} onClose={onClose} isUpdate={isUpdate} />}
      <div className={clsx('flex h-full flex-col flex-grow relative', !isInline && 'scroll overflow-auto')}>
        <div className='w-full'>
          <div className="relative w-full">
            <div className={clsx('w-full', isModal && 'overflow-auto', !isInline && "scroll")} style={isModal ? { maxHeight: '90vh' } : {}}>
              <div className="box-border flex w-full flex-col gap-4 px-6 py-4">
                <FormComponent update={update} remove={remove} isInline={isInline} readOnly={readOnly} disabled={disabled} isMutationLoading={isMutationLoading} onClose={onClose} values={initialValues} form={form} isUpdate={isUpdate} valuesChange={valuesChange} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormAnt>
  )

  if (!profile) {
    return null
  }

  if (isInline) {
    return innerForm
  }

  return (
    <>
      <ConfirmSaveModal
        open={isModalConfirmOpen}
        onOk={() => [closeForm(), setIsModalConfirmOpen(false)]}
        onCancel={() => setIsModalConfirmOpen(false)}
      />

      {isModal && isOpen && (
        <Modal
          styles={{
            content: {
              padding: 0,
              background: 'transparent',
              overflow: 'hidden',
              borderRadius: 6,
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
              border: '1px solid ' + token.colorBorderLayout,
              background: token.colorBgContainer,
            },
          }}
          width={600}
          closeIcon={false}
          footer={null}
          centered
          open={isOpen}
          onCancel={onClose}
        >
          {innerForm}
        </Modal>
      )}

      {!isModal && (
        <Drawer
          width={500}
          placement="right"
          closable={false}
          onClose={onClose}
          open={isOpen}
          getContainer={false}
          styles={{
            wrapper: {
              boxShadow: 'none',
              background: 'transparent',
              borderRadius: 6,
              padding: 8,
              boxSizing: 'content-box',
            },
            content: {
              borderRadius: 6,
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
              border: '1px solid ' + token.colorBorderLayout,
              background: token.colorBgContainer,
            },
            body: { padding: 0 },
          }}
        >
          {innerForm}
        </Drawer>
      )}
    </>
  )
}
