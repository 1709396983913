import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Dropdown, Typography } from 'antd'

const { Text } = Typography

export default function CellHeaderExtra({ column }) {
  if (column.colDef.headerComponentParams.compact) {
    return null
  }

  //@TRANSLATE
  return (
    <Dropdown
      menu={{
        items: [
          { label: 'Text', icon: <FontAwesomeIcon fixedWidth icon={light('align-left')} /> },
          { label: 'Number', icon: <FontAwesomeIcon fixedWidth icon={light('hashtag')} /> },
          { label: 'Date', icon: <FontAwesomeIcon fixedWidth icon={light('calendar')} /> },
          { label: 'Select', icon: <FontAwesomeIcon fixedWidth icon={light('list-dropdown')} /> },
          { label: 'Relation', icon: <FontAwesomeIcon fixedWidth icon={light('link')} /> },
        ],
      }}
      trigger={['click']}
      placement="bottom"
    >
      <div className="box-border inline-flex w-full gap-1.5 overflow-hidden text-ellipsis relative right-2.5">
        <Button
          type="text"
          className="rounded-none"
          icon={
            <Text type="secondary">
              <FontAwesomeIcon icon={light('plus')} />
            </Text>
          }
        />
      </div>
    </Dropdown>
  )
}
