import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography, Badge, theme, Tooltip } from 'antd'
import clsx from 'clsx'

const { useToken } = theme
const { Text } = Typography

export default function Label({ style, className, icon, label, children, count, actionsStart, actionsEnd, info, isSwitch, isStrong, noFlex }) {
  const { token } = useToken()

  return (
    <div className={clsx(!noFlex && "flex-1 flex flex-col gap-1", className)} style={isSwitch ? {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      ...style
    } : style}>
      <div className="flex items-center justify-between">
        <div className="flex cursor-pointer select-none items-center gap-2">
          {icon && (
            <Text className="text-label">
              <FontAwesomeIcon icon={icon} />
            </Text>
          )}
          <Text className="text-label" style={isStrong ? { fontWeight: 'bold' } : {}}>{label}</Text>
          {!!count && (
            <Badge
              style={{
                backgroundColor: token.colorFillSecondary,
                color: token.colorTextTertiary,
              }}
              count={count}
            ></Badge>
          )}
          {info && (
            <Tooltip title={info}>
              <FontAwesomeIcon className='text-description' icon={light('circle-info')} />
            </Tooltip>
          )}
          {actionsStart}
        </div>
        {actionsEnd && <div>{actionsEnd}</div>}
      </div>
      {children}
    </div>
  )
}
