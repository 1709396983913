import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Empty, Button, Typography } from "antd"
import { useTranslation } from 'react-i18next'

const { Text } = Typography

export default function EmptyData({ icons, compact, openForm, section, translateKey }) {
  const { t } = useTranslation()
  
  return (
    <div className="flex w-full grow items-center justify-center p-6 box-border">
      <Empty
        image={null}
        imageStyle={{ display: 'none' }}
        description={
          <div className="flex flex-col gap-4">
            <Text type="secondary">
              <FontAwesomeIcon icon={section.icon || icons.duotone} size={compact ? "3x" : "5x"} />
            </Text>
            <Text type="secondary">
              <strong>{t(`${translateKey}.page.sections.${section.value}.empty-primary`)}</strong>
              <br />
              {t(`${translateKey}.page.sections.${section.value}.empty-secondary`)}
            </Text>
          </div>
        }
      >
        {!section.suppressInsertRow && !compact && <Button onClick={() => openForm()} type="primary">
          {t(translateKey + '.form.insert')}
        </Button>}
      </Empty>
    </div>
  )
}
