import { duotone, light, regular, solid, thin } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dropdown, Input, List, Modal, Slider, Tooltip, Typography } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Avatar from "src/@components/Avatar";
import Label from "src/@components/Label";
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import Textarea from "src/@components/form/Textarea";
import PageHeader from "src/@components/page/PageHeader";
import { supabase } from "src/supabase";
import logo from "src/assets/universus-black.png"
import { useProfile } from "src/profiles/ProfilesApi";
import clsx from "clsx";
import Form from "src/@components/form/Form";
import FoldersForm, { FoldersFormInitialValues } from "src/folders/FoldersForm";
import { useFolder, useRemoveFolder, useUpdateFolder } from "src/folders/FoldersApi";
import { useSettings } from "src/settings/SettingsApi";
import { useQueryClient } from "@tanstack/react-query";

export default function Folder({ folder, setFolder, initialValues }) {
    const { t } = useTranslation()
    const { mutate: update } = useUpdateFolder({ queryParams: {} })
    const { mutate: remove } = useRemoveFolder({ queryParams: {} })
    const { data: profile } = useProfile()
    const { data: settings } = useSettings()

    const readOnly = (settings && settings.permissions && !settings.permissions['update-folder'])

    return <div style={{ height: '100%', padding: '10px 16px', display: 'flex', flexDirection: 'column', gap: 28 }}>
        <Form
            syncRequests={true}
            readOnly={readOnly}
            captureResult={(result) => setFolder({ ...folder, ...result })}
            isOpen={true}
            FormComponent={FoldersForm}
            initialValues={initialValues}
            update={update}
            remove={remove}
            isInline={true}
        />
    </div>
}