import * as Sentry from "@sentry/react";
import { version } from "../package.json";

const { APP_SENTRY, APP_RELEASE, MODE } = import.meta.env

Sentry.init({
  release: APP_RELEASE,
  environment: MODE,
  dsn: APP_SENTRY,
  integrations: [
    Sentry.browserTracingIntegration()
  ],
  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception && event.event_id) {
      Sentry.showReportDialog({
        eventId: event.event_id,
        user: {
          name: '_'
        },
        onClose() {
          // Refresh the page after the user closes the report dialog
          location.reload();
        }
      });
    }
    return event;
  },
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});